import { gql } from "@apollo/client";


export const LOGIN_USER_QUERY = gql`
query($mobileNumber: String!, $password: String!) {
    loginUser(mobileNumber: $mobileNumber, password: $password) {
      __typename
      userId
      token
      mobileNumber
      validFrom
      name
      emailVerifiedDate
      school {
        id
        name
      }
      teacher {
        id
        name
        school {
          id
        }
      }
      error {
        statusCode
        message
      }
    }
  }
`
export const VALIDATE_PARENT_KID_MUTATION = gql`
query ($data: schoolParentKidInputList) {
    validateCreateParentKid (
        data: $data
    ) {
        __typename
        items { 
            sno
            parentId
            pincode
            parentName
            mobileNumber
            kids { 
                kidId
                clazzId
                studentName
                gender
                dob
            }
            existingParentId
            sameParentAsSno
            isParentStudentCreated
            validationMessage
        }
        isInputListHasValidationErrors
        error { 
            statusCode
            message
        }
    }
}
`
export const GET_ALL_SCHOOL_QUERY = gql`
query($searchString: String, $pageNumber: Float, $pageLimit: Float) {
  getAllSchools(
    searchString: $searchString
    pageNumber: $pageNumber
    pageLimit: $pageLimit
  ) {
    __typename
    items {
      id
      name
      email
      mobileNumber
      address1
      address2
      district
      state
      pincode
      error {
        statusCode
        message
      }
    }
    pagination {
      pageNumber
      pageLimit
      totalCount
    }
  }
}
`
export const GET_SCHOOL_DROPDOWN_QUERY = gql`
query ($teacherId: String, $schoolId: String) {
    getSchoolDropDownOptions (
        teacherId: $teacherId,
        schoolId: $schoolId
    ) {
        __typename
        options { 
            value
            label
        }
    }
}
`
export const GET_TEACHER_DROPDOWN_QUERY = gql`
query ($schoolId: String) {
  getTeacherDropDownOptions (
      schoolId: $schoolId
  ) {
      __typename
      options { 
          value
          label
      }
  }
}
`

export const GET_CLASS_DROPDOWN_QUERY = gql`
query ($teacherId: String, $schoolId: String) {
  getClazzDropDownOptions (
      teacherId: $teacherId,
      schoolId: $schoolId
  ) {
      __typename
      options { 
          value
          label
      }
  }
}
`

export const GET_ALL_TEACHER_QUERY = gql`
query ($searchString: String, $pageNumber: Float, $pageLimit: Float, $schoolId: String) {
  getAllTeachers (
      searchString: $searchString,
      pageNumber: $pageNumber,
      pageLimit: $pageLimit,
      schoolId: $schoolId
  ) {
      __typename
      items { 
          id
          name
          mobileNumber
          email
          specialization
          schoolId
          school {
            name
          }
          error { 
              statusCode
              message
          }
      }
      pagination { 
          pageNumber
          pageLimit
          totalCount
      }
  }
}
`

export const GET_ALL_CLASS_QUERY = gql`
query ($teacherId: String, $pageNumber: Float, $pageLimit: Float, $schoolId: String) {
  getAllClazz (
      teacherId: $teacherId,
      pageNumber: $pageNumber,
      pageLimit: $pageLimit,
      schoolId: $schoolId
  ) {
      __typename
      items { 
          id
          standard
          section
          alias
          schoolId
          teacherId
          school { 
              id
              name
              email
              mobileNumber
              address1
              address2
              district
              state
              pincode
              error { 
                  statusCode
                  message
              }
          }
          teacher { 
              id
              name
              mobileNumber
              email
              specialization
              schoolId
              school { 
                  id
                  name
                  email
                  mobileNumber
                  address1
                  address2
                  district
                  state
                  pincode
                  error { 
                      statusCode
                      message
                  }
              }
              error { 
                  statusCode
                  message
              }
          }
          error { 
              statusCode
              message
          }
      }
      pagination { 
          pageNumber
          pageLimit
          totalCount
      }
  }
}
`

export const GET_SMS_TABLE_SEARCH = gql`
query ($schoolId: String!, $classId: String, $smsType: String, $deliveryStatus: String) {
    getSMSs (
        schoolId: $schoolId,
        classId: $classId,
        smsType: $smsType,
        deliveryStatus: $deliveryStatus
    ) {
        __typename
        items { 
            id
            parentId
            smsType
            sentAt
            message
            deliveryStatus
            parent { 
                name
                mobileNumber
            }
            smsKidInfo { 
                kidsName
                className
            }
            error { 
                statusCode
                message
            }
        }
        pagination { 
            pageNumber
            pageLimit
            totalCount
        }
    }
}
`


export const GET_ALL_STUDENTS_QUERY = gql`
query ($clazzId: String, $teacherId: String, $schoolId: String, $pageNumber: Float, $pageLimit: Float) {
    getStudents (
        clazzId: $clazzId,
        teacherId: $teacherId,
        schoolId: $schoolId,
        pageNumber: $pageNumber,
        pageLimit: $pageLimit
    ) {
        __typename
        items { 
            id
            name
            dob
            gender
            parentId
            clazzId
          	class
            parent { 
                id
                name
                pincode
                mobileNumber
                user {
                  lastLogin
                }
                error { 
                    statusCode
                    message
                }
            }
            error { 
                statusCode
                message
            }
        }
        pagination { 
            pageNumber
            pageLimit
            totalCount
        }
        error { 
            statusCode
            message
        }
    }
}
`

export const GET_PROGRESS_REPORT = gql`
query ($schoolId: String!, $classId: String, $kidId: String, $fromDate: String!, $toDate: String!) {
    getKidProgressReport (
        schoolId: $schoolId,
        classId: $classId,
        kidId: $kidId,
        fromDate: $fromDate,
        toDate: $toDate
    ) {
        __typename
        items { 
            kidId
            name
            class
            taskScoreAvg
            assessmentScoreAvg
            quizScoreAvg
            greenStarScoreAvg
            totalScoreAvg
            goldenStarAward
            teachersMark
        }
        pagination { 
            pageNumber
            pageLimit
            totalCount
        }
        error { 
            statusCode
            message
        }
    }
}
`

export const VERIFY_EMAIL_OTP = gql`
query ($email: String!, $emailOtp: String!) {
    verifyEmailOTP (
        email: $email,
        emailOtp: $emailOtp
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}
`
