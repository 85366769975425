import { useMemo, useState, useEffect } from 'react';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Dialog,
    DialogContentText,
    Typography,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_ALL_STUDENTS_QUERY } from '../../../helpers/api/Queries.js';
import { UPDATE_STUDENT } from '../../../helpers/api/Mutation.js';
import { QueryRequest, MutateRequest } from '../../../helpers/api/service_helper.js'
import { setStudentTableData } from '../../../redux/common_reducer.js';
import { useDispatch, useSelector } from 'react-redux';
import { notifySuccess, notifyWarning, notifyError } from '../../../common/notifications.js';
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Example = ({ selectedSchool }) => {
    const studentTableData = useSelector((state) => state.common.studentTableData)
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState([]);
    const classes = useSelector((state) => state.common.classes);
    const [currentClazz, setCurrentClazz] = useState(null);
    const [loading, setLoading] = useState(true)
    const [modalLoading, setModalLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            // If no school is selected, set loading to false and studentTableData to an empty array
            if (!selectedSchool) {
                setIsLoading(false);
                dispatch(setStudentTableData([]));
                return;
            }

            await new Promise((resolve) => setTimeout(resolve, 1000));
            // before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
            // this condition is retrieve students based on the loged in user role
            const teacherIdGet = getItemFromLocalStorage('teacher_id');
            const queryParam = {};
            if (teacherIdGet) {
                queryParam.teacherId = teacherIdGet.id
            } else {
                queryParam.schoolId = selectedSchool.value
            }
            const response = await QueryRequest(GET_ALL_STUDENTS_QUERY, queryParam);
            dispatch(setStudentTableData(response.data.getStudents.items || []));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedSchool]);

    // function editOptions() {
    //     const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
    //     if (superAdminIdGet) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // Modal to show the exisiting parent's kids information 
    const addExistingModal = ({ modalVisible, setModalVisible, errorMessage }) => {

        const handleClose = () => {
            setModalVisible(false);
            setLoading(false);
        };

        //Update the exisiting parent to new kid
        const handleSubmit = async () => {
            setLoading(true);
            const data = {
                data: {
                    newClazzId: errorMessage[2],
                    kidId: errorMessage[1].id,
                    newMobileNumber: errorMessage[1]['parent.mobileNumber'],
                    newParentId: errorMessage[3],
                    userAgreedToSwapKidToExistingParent: "Yes",
                    clazzIdModified: errorMessage[4]
                }
            }
            const updateResponse = await MutateRequest(UPDATE_STUDENT, data);
            if (updateResponse.data.updateStudent.status === "Success") {
                notifySuccess("Mobile number updated successfully")
            } else {
                const errorMsg = "Error occured in updating existing parent mobile number"
                notifyError(errorMsg)
                console.error(errorMsg)
            }
            setModalVisible(false);
            table.setEditingRow(null);
            setLoading(false);
            await fetchData()
        };

        const buttonStyle = {
            border: '2px solid #1E40AF',
            color: '#FFFFFF',
            backgroundColor: '#1E40AF',
            borderRadius: '5px',
            transition: 'all 0.3s',
            cursor: 'pointer',
        };

        return (
            <Dialog open={modalVisible} onClose={handleClose}>
                <DialogTitle style={{ fontWeight: "bold" }}>
                    Add the student to an existing parent?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span dangerouslySetInnerHTML={{ __html: errorMessage[0] }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button
                        onClick={handleClose}
                        style={buttonStyle}
                    >
                        Change mobile Number
                    </Button>
                    <Button
                        disabled={loading}
                        style={buttonStyle}
                        onClick={handleSubmit}
                    >
                        {loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <Typography>Submit</Typography>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                Edit: () => null,
                enableEditing: false,
                hidden: true,
                enableHiding: true,
                visibleInShowHideMenu: false,
                columnVisibility: false

            },
            {
                accessorKey: 'parent.id',
                header: 'Parent ID',
                Edit: () => null,
                enableEditing: false,
                hidden: true,
                enableHiding: true,
                visibleInShowHideMenu: false,
                columnVisibility: false
            },
            {
                accessorKey: 'clazzId',
                header: 'Class ID',
                Edit: () => null,
                enableEditing: false,
                hidden: true,
                enableHiding: true,
                visibleInShowHideMenu: false,
                columnVisibility: false
            },
            {
                accessorKey: 'name',
                header: 'Student Name',
                enableEditing: false,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                accessorKey: 'gender',
                header: 'Student Gender',
                enableEditing: false,
                muiEditTextFieldProps: {
                    required: true,
                    select: true,
                    error: !!validationErrors?.gender,
                    helperText: validationErrors?.gender,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            gender: undefined,
                        }),
                },
                editSelectOptions: ['Male', 'Female', 'Other'],

            },
            {
                accessorKey: 'dob',
                header: 'Student DOB',
                enableEditing: false,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.dob,
                    helperText: validationErrors?.dob,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            dob: undefined,
                        }),
                },
            },
            {
                accessorKey: 'class',
                header: 'Class',
                enableEditing: false,
                Edit: ({ row }) => {
                    const clazzId = row.original.clazzId;
                    const clazz = row.original.class;
                    const [selectedClazz, setSelectedClazz] = useState({ value: clazzId, label: clazz });

                    const handleClazzChange = (selectedOption) => {
                        setSelectedClazz(selectedOption);
                        setCurrentClazz(selectedOption);
                    };

                    return (
                        <div>
                            <p style={{ color: validationErrors.class ? "#D32F2F" : "GrayText", fontFamily: "sans-serif", fontSize: 12, marginBottom: 10 }}>Class<span className='pl-2'>*</span></p>
                            <Select
                                value={selectedClazz}
                                onChange={handleClazzChange}
                                options={classes}
                                isSearchable
                                components={makeAnimated()}
                                onFocus={() =>
                                    setValidationErrors((prevErrors) => ({
                                        ...prevErrors,
                                        ['class']: undefined,
                                    }))
                                }
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor: validationErrors['class'] ? "#D32F2F" : base.borderColor,
                                    }),
                                }}
                            />
                            {validationErrors['class'] && (
                                <p style={{ color: "#D32F2F", fontSize: 12, fontFamily: "sans-serif", padding: 5 }}>{validationErrors['class']}</p>
                            )}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'parent.name',
                header: 'Parent Name',
                enableEditing: false,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.['parent.name'],
                    helperText: validationErrors?.['parent.name'],
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            ['parent.name']: undefined,
                        }),
                },
            },
            {
                accessorKey: 'parent.mobileNumber',
                header: 'Mobile Number',
                enableEditing: true,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.['parent.mobileNumber'],
                    helperText: validationErrors?.['parent.mobileNumber'],
                    // Remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            ['parent.mobileNumber']: undefined,
                        })),
                },
            },
            {
                accessorKey: 'parent.user.lastLogin',
                header: 'Parent Signed Up?',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                Cell: ({ row }) => {
                    const data = row.original.parent.user.lastLogin
                    let text = '';
                    let color = '';

                    if (data === 'Not logged in') {
                        text = 'No';
                        color = 'red';
                    } else {
                        text = 'Yes';
                        color = 'green';
                    }
                    return (
                        <span style={{ color }}>{text}</span>
                    );
                },
            },
        ],
        [validationErrors],
    );

    // Update action 
    const handleSaveUser = async ({ values, table }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        setModalLoading(true)
        try {
            function clazzChange() {
                if (currentClazz === null) {
                    return values.clazzId
                } else {
                    return currentClazz.value
                }
            }
            const newClazzId = clazzChange();
            const data = {
                data: {
                    newClazzId: newClazzId,
                    kidId: values.id,
                    newMobileNumber: values["parent.mobileNumber"]
                }
            }
            // validate the input mobile number if existing parent show modal with message 
            const updateResponse = await MutateRequest(UPDATE_STUDENT, data);
            if (updateResponse.data.updateStudent.status === "Success") {
                notifySuccess("Updated successfully")
                table.setEditingRow(null);
                await fetchData()
            }
            if (updateResponse.data.updateStudent.error.statusCode === "423") {
                const errorMessage = updateResponse.data.updateStudent.error.message;
                const newError = { 'parent.mobileNumber': errorMessage, 'class': errorMessage };
                setValidationErrors(newError);
                return;
            }
            if (updateResponse.data.updateStudent.error.statusCode === "422") {
                const errMessage = updateResponse.data.updateStudent.error.message
                const newParentId = updateResponse.data.updateStudent.newParentId
                const clazzIdModified = updateResponse.data.updateStudent.clazzIdModified
                setModalVisible(true);
                setModalErrorMessage([errMessage, values, newClazzId, newParentId, clazzIdModified])
                return;
            }
            if (updateResponse.data.updateStudent == null) {
                notifyError("Failed to update. Please try again later.")
                table.setEditingRow(null);
                await fetchData()
            }
        } catch (error) {
            console.error('Error updating in handleSaveUser:', error);
        } finally {
            setModalLoading(false)
        }
    }


    //DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this user?')) {
    //         // deleteUser(row.original.id);
    //     }
    // };
    // Export data
    const handleExportData = () => {
        // If there is no data, notify a warning
        if (!studentTableData || studentTableData.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Get the current state of studentTableData
        const currentData = studentTableData.map(item => ({
            'Student Name': item.name || '',
            'Student Gender': item.gender || '',
            'Student DOB': item.dob || '',
            'Class': item.class || '',
            'Parent Name': item.parent?.name || '',
            'Parent Phone Number': item.parent?.mobileNumber || '',
        }));

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(currentData);

        // Adjust column widths
        const columnWidths = [
            { wch: 20 }, // Student Name
            { wch: 15 }, // Student Gender
            { wch: 15 }, // Student DOB
            { wch: 20 }, // Class
            { wch: 20 }, // Parent Name
            { wch: 20 }  // Parent Phone Number
        ];
        worksheet['!cols'] = columnWidths;

        // Generate XLSX file
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');

        // Save the XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'students.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };


    const table = useMaterialReactTable({
        columns,
        data: studentTableData,
        editDisplayMode: 'modal',
        initialState: { columnVisibility: { ["parent.id"]: false, id: false, clazzId: false } },
        muiTablePaperProps: ({ table }) => ({
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        enableEditing: true,
        getRowId: (row) => row.id,
        onCreatingRowCancel: () => setValidationErrors({}),
        onEditingRowCancel: () => {
            setValidationErrors({});
            setModalLoading(false);
            setLoading(false);
        },
        onEditingRowSave: handleSaveUser,

        // optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Edit User</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', paddingBottom: 15 }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    {modalLoading ? (
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                onClick={() => {
                                    table.setEditingRow(null);
                                    setValidationErrors({});
                                    setModalLoading(false);
                                }}
                            >
                                CANCEL
                            </Button>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#FFFFFF",
                                    backgroundColor: "#1976D2",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    width: "100px",
                                    marginLeft: "20px",
                                }}
                            >
                                <CircularProgress size={30} sx={{ color: "white" }} />
                            </Box>
                        </Box>
                    ) : (
                        <MRT_EditActionButtons disabled={modalLoading} variant="text" table={table} row={row} />
                    )}
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                sx={{ backgroundColor: 'rgb(30 58 138)' }}

            >
                Export All Data
            </Button>
        ),
        state: {
            isLoading: isLoading,
            // isSaving: isUpdatingUser || isDeletingUser,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return (
        <>
            {addExistingModal({ modalVisible, setModalVisible, errorMessage: modalErrorMessage })}
            <MaterialReactTable table={table} />
        </>
    );
};

const AddStudentFliterTable = ({ selectedSchool }) => (
    //Put this with your other react-query providers near root of your app
    <Example selectedSchool={selectedSchool} />

);

export default AddStudentFliterTable;

const validateRequired = (value) => !!value.length;
const validatePhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);

function validateUser(user) {

    return {
        name: !validateRequired(user.name)
            ? 'Student name is Required'
            : '',
        gender: !validateRequired(user.gender)
            ? 'Student Gender is Required'
            : '',
        dob: !validateRequired(user.dob)
            ? 'Student DOB is Required'
            : '',
        class: !validateRequired(user.class)
            ? 'Student Class is Required'
            : '',
        ['parent.name']: !validateRequired(user["parent.name"])
            ? 'Parent name is Required'
            : '',
        ['parent.mobileNumber']: !validateRequired(user["parent.mobileNumber"])
            ? 'Parent Mobile number is required'
            : !validatePhoneNumber(user["parent.mobileNumber"])
                ? 'Invalid Parent Mobile number'
                : '',

    };
}
