import { useMemo, useState, useEffect } from 'react';
import {
    // MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import { Button, } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MutateRequest, QueryRequest } from '../../../../helpers/api/service_helper.js'
import { useDispatch, useSelector } from 'react-redux';
import { notifySuccess, notifyError, notifyWarning } from '../../../../common/notifications.js';
import { setStudentTableData } from '../../../../redux/common_reducer.js';
import { CREATE_PARENT_KID_MUTATION } from '../../../../helpers/api/Mutation.js';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearForm } from '../../../../redux/formReducer.js';
import * as React from "react"
import { GET_ALL_STUDENTS_QUERY } from '../../../../helpers/api/Queries.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getItemFromLocalStorage } from '../../../../helpers/api/utils.js';

const Example = ({ setShowTable, selectedSchool }) => {
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({});
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const bulkUploadFile = useSelector((state) => state.common.bulkUploadFile);
    const isInputListHasValidationErrors = bulkUploadFile.isInputListHasValidationErrors;

    useEffect(() => {
        if (bulkUploadFile) {
            setData(bulkUploadFile.items);
            setIsLoading(false);
        }

    }, [bulkUploadFile]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'validationMessage',
                header: 'Validation message',
                enableEditing: false,
                Cell: ({ cell }) => (
                    <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
                ),
            },
            {
                accessorKey: 'sno',
                header: 'S No',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.sno,
                    helperText: validationErrors?.sno,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            sno: undefined,
                        }),
                },
            },
            {
                accessorKey: 'parentName',
                header: 'Parent Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.parentName,
                    helperText: validationErrors?.parentName,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            parentName: undefined,
                        }),
                },
            },
            {
                accessorKey: 'mobileNumber',
                header: 'Parent Phone No',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.mobileNumber,
                    helperText: validationErrors?.mobileNumber,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            mobileNumber: undefined,
                        }),
                },
            },
            {
                accessorKey: 'pincode',
                header: 'Pincode',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.pincode,
                    helperText: validationErrors?.pincode,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            pincode: undefined,
                        }),
                },
            },
            {
                accessorKey: 'kids.0.studentName',
                header: 'Student Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            }, {
                accessorKey: 'kids.0.gender',
                header: 'Gender',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.gender,
                    helperText: validationErrors?.gender,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            gender: undefined,
                        }),
                },
            },
            {
                accessorKey: 'kids.0.dob',
                header: 'Date of Birth',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.dob,
                    helperText: validationErrors?.dob,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            dob: undefined,
                        }),
                },
            },


        ],
        [validationErrors],
    );
    // const [editedUser, setEditedUser] = useState(null);
    //UPDATE action
    // const handleSaveUser = async ({ values, table }) => {
    //     try {
    //         setIsUpdatingUser(true);

    //         const userIndex = data.findIndex((row) => row.id === values.id);

    //         if (userIndex === -1) {
    //             console.error('Student not found in the data array.');
    //             return;
    //         }
    //         const newData = [...data];
    //         newData[userIndex] = { ...newData[userIndex], ...values };
    //         setData(newData);

    //         notifySuccess('The Student has been successfully edited');

    //         await new Promise((resolve) => setTimeout(resolve, 1000));

    //         // Close the editing modal
    //         table.setEditingRow(null);
    //     } catch (error) {
    //         console.error('Error updating user:', error);
    //     } finally {
    //         setIsUpdatingUser(false);
    //     }
    // };

    // // TODO
    // const deleteUser = async ({ values, table }) => {
    //     try {
    //         setIsDeletingUser(true);

    //         const userId = values.id;

    //         if (!userId) {
    //             console.error('Student ID is missing or undefined.');
    //             return;
    //         }
    //         const newData = data.filter((row) => row.id !== userId);
    //         setData(newData);
    //         notifySuccess('The Student has been successfully deleted');
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //     } catch (error) {
    //         console.error('Error deleting user:', error);
    //     } finally {
    //         setIsDeletingUser(false);
    //     }
    // };

    // TODO

    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this Student?')) {
    //         deleteUser({ values: row, table });
    //     }
    // };

    const handleExportData = () => {
        // If there is no data, notify a warning
        if (!data || data.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Auto-size columns
        const colWidths = [];
        Object.keys(data[0]).forEach(key => {
            colWidths.push({ wch: key.length + 2 }); // Add some padding
        });
        worksheet['!cols'] = colWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Save the XLSX file
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'Bulkupload.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };


    const handleSubmit = async () => {
        try {
            let items = [];

            const requestData = data.map(item => {
                const pincode = item.pincode;
                return {
                    sno: item.sno,
                    parentName: item.parentName,
                    mobileNumber: item.mobileNumber,
                    pincode: pincode,
                    kids: item.kids.map(kid => ({
                        clazzId: kid.clazzId,
                        studentName: kid.studentName,
                        gender: kid.gender,
                        dob: kid.dob
                    }))
                };
            });
            items.push(requestData);
            const response = await MutateRequest(CREATE_PARENT_KID_MUTATION, {
                data: { items: requestData }
            });
            if (response.data.createSchoolParentKid.error) {
                console.error(response.data.createSchoolParentKid.error.message);
                notifyError(response.data.createSchoolParentKid.error.message);
                return;
            } else if (response.data.createSchoolParentKid.error === null) {
                //  Reload the student table as bulk upload student got added successfully 
                // After adding the student condition is retrieve students based on the loged in user role
                const teacherIdGet = getItemFromLocalStorage('teacher_id');
                const queryParam = {};
                if (teacherIdGet) {
                    queryParam.teacherId = teacherIdGet.id
                } else {
                    queryParam.schoolId = selectedSchool.value
                }
                try {
                    const response = await QueryRequest(GET_ALL_STUDENTS_QUERY, queryParam);
                    dispatch(setStudentTableData(response?.data?.getStudents?.items))
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                notifySuccess("Bulk upload was successful");
                setShowTable(false)
                const formElement = document.getElementById('bulkUploadForm');
                formElement.reset();
                dispatch(clearForm({ formName: 'bulkUpload' }));
            }

        } catch (error) {
            console.error('Error creating Students:', error);
            notifyError('An error occurred while saving. Please try again.');
        }
    };


    const table = useMaterialReactTable({
        columns,
        data,
        // editDisplayMode: 'modal',
        // enableEditing: true,
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        initialState: { columnVisibility: { id: false } },
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoading
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        // onCreatingRowSave: handleCreateUser,
        // onEditingRowCancel: () => setValidationErrors({}),
        // onEditingRowSave: handleSaveUser,


        // renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
        //     <>
        //         <DialogTitle variant="h3">Edit User</DialogTitle>
        //         <DialogContent
        //             sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        //         >
        //             {internalEditComponents}
        //         </DialogContent>
        //         <DialogActions>
        //             <MRT_EditActionButtons variant="text" table={table} row={row} />
        //         </DialogActions>
        //     </>
        // ),
        // renderRowActions: ({ row, table }) => (
        //     <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
        //         <Tooltip title="Edit">
        //             <IconButton onClick={() => table.setEditingRow(row)}>
        //                 <EditIcon />
        //             </IconButton>
        //         </Tooltip>
        //         <Tooltip title="Delete" >
        //             <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
        //                 <DeleteIcon />
        //             </IconButton>
        //         </Tooltip>
        //     </Box>
        // ),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className='flex' sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    sx={{ backgroundColor: 'rgb(30 58 138)', mr: 1 }}
                >
                    Export All Data
                </Button>
                {/* {!isInputListHasValidationErrors && ( */}
                <Button
                    disabled={isInputListHasValidationErrors}
                    type="button"
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        bgcolor: '#1E3A8A',
                        color: '#fff',
                        px: '3rem',
                        py: '0.5rem',
                    }}
                >
                    <FontAwesomeIcon icon={faUpload} size="lg" className='pr-2' /> Submit
                </Button>
                {/* )} */}
            </div>
        ),

        state: {
            isLoading: isLoading,
            isSaving: isUpdatingUser || isDeletingUser,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return (
        <>

            <MaterialReactTable table={table} />


        </>
    )
        ;
};

const BulkUploadTable = ({ setShowTable, selectedSchool }) => (
    <Example setShowTable={setShowTable} selectedSchool={selectedSchool} />
);

export default BulkUploadTable;

// const validateRequired = (value) => !!value.length;
// const validatePhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);
// const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// function validateTeacher(user) {
//     return {
//         schoolID: !validateRequired(user.schoolID)
//             ? 'School ID is Required'
//             : '',
//         teacherName: !validateRequired(user.teacherName)
//             ? 'Teacher Name is Required'
//             : '',
//         teacherSpecialization: !validateRequired(user.teacherSpecialization)
//             ? 'Specialization is Required'
//             : '',
//         teacherPhoneNumber: !validateRequired(user.teacherPhoneNumber)
//             ? 'Phone Number is Required'
//             : !validatePhoneNumber(user.teacherPhoneNumber)
//                 ? 'Invalid Phone Number'
//                 : '',
//         teacherEmailID: !validateRequired(user.teacherEmailID)
//             ? 'Email is Required'
//             : !validateEmail(user.teacherEmailID)
//                 ? 'Invalid Email'
//                 : '',
//     };
// }
