import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

export default function NotifyModal({ open, setOpen, hasValidationErrors }) {

    const buttonStyle = {
        border: '2px solid #1E40AF',
        color: '#FFFFFF',
        backgroundColor: '#1E40AF',
        borderRadius: '5px',
        transition: 'all 0.3s',
        cursor: 'pointer',
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpen(false);
                    }
                }}
            >
                <DialogTitle style={{ fontWeight: "bold" }}>
                    {hasValidationErrors ? "Data has validation errors, fix the error in excel and upload again!" : "Data validation successful, review once and click submit button to save the data"}

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>
                        {/* Content of the dialog */}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        onClick={() => setOpen(false)}
                        style={buttonStyle}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
