import header_logo from "../../images/header-logo.png"
import backgroundImage from "../../images/login-bg.png"
import logo from "../../images/logo.png"
import addteachericon from "../../images/Add_teacher_icon.png"
import addclassicon from "../../images/Add_class_icon.png"
import addprogressicon from "../../images/Add_progress_icon.png"
import addschoolicon from "../../images/Add_school_icon.png"
import addstudenticon from "../../images/Add_student_icon.png"
import ukiddiySchoolLogo from "../../images/uKiddiy_school.png"


export const HEADER_LOGO = header_logo
export const LOGIN_BACKGROUND_IMAGE = backgroundImage
export const UKIDDIY_LOGO = logo
export const SCHOOL_ICON = addschoolicon
export const TEACHER_ICON = addteachericon
export const CLASS_ICON = addclassicon
export const STUDENT_ICON = addstudenticon
export const PROGRESS_ICON = addprogressicon
export const UKIDDIY_SCHOOL_LOGO = ukiddiySchoolLogo


