import React, { useState, useEffect } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Stack, Typography } from '@mui/material';
import { Assessment } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

export default function PieChartProgress({ tableData }) {
    const [data, setData] = useState(tableData);
    const [type, setType] = useState('task');

    const [dataDummy, setDataDummy] = useState([
        { id: 0, value: 0, label: '< 40' },
        { id: 1, value: 0, label: '40 - 75' },
        { id: 2, value: 0, label: '75 - 100' },
    ]);

    useEffect(() => {
        setData(tableData);
        const calculatePercentages = (data, type) => {
            let lt40 = 0;
            let gt40lt75 = 0;
            let gt75 = 0;

            data.forEach(value => {
                const score = value[`${type}ScoreAvg`];
                if (score < 40) {
                    lt40++;
                } else if (score >= 40 && score < 75) {
                    gt40lt75++;
                } else {
                    gt75++;
                }
            });

            lt40 = Math.round((lt40 / data.length) * 100);
            gt40lt75 = Math.round((gt40lt75 / data.length) * 100);
            gt75 = Math.round((gt75 / data.length) * 100);

            return [lt40, gt40lt75, gt75];
        };

        const updateDataDummy = () => {
            const percentages = calculatePercentages(tableData, type);
            setDataDummy(prevData => {
                return prevData.map((item, index) => {
                    return {
                        ...item,
                        value: percentages[index]
                    };
                });
            });
        };

        updateDataDummy();

    }, [tableData, type]);

    return (
        <Stack sx={{ marginBottom: 10 }}>
            <Typography sx={{ alignSelf: "center", fontWeight: "bold", marginBottom: 10, paddingRight: 10 }}>Students overall performance</Typography>
            <Box sx={{ width: '100%' }}>
                <TextField
                    select
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    label="Type"
                    sx={{ minWidth: 150 }}
                >
                    <MenuItem value="task">Task</MenuItem>
                    <MenuItem value="assessment">Self Assessment</MenuItem>
                    <MenuItem value="quiz">Quiz</MenuItem>
                    <MenuItem value="greenStar">Green star</MenuItem>
                </TextField>
            </Box>
            <PieChart
                series={[
                    {
                        data: dataDummy,
                        arcLabel: (item) => `${item.value} %`,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 50, additionalRadius: -30, color: 'gray' },

                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 20
                    },
                }}
                height={500}
            />
        </Stack>
    );
}
