// notifications.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { marginTop: '10px' }, // Add custom margin-top

};

export const notifySuccess = (message, options = {}) => {
    toast.success(message, { ...defaultOptions, ...options });
};

export const notifyError = (message, options = {}) => {
    toast.error(message, { ...defaultOptions, ...options });
};

export const notifyInfo = (message, options = {}) => {
    toast.info(message, { ...defaultOptions, ...options });
};

export const notifyWarning = (message, options = {}) => {
    toast.warning(message, { ...defaultOptions, ...options });
};
