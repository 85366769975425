import { useMemo, useState, useEffect } from 'react';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_ALL_CLASS_QUERY, GET_TEACHER_DROPDOWN_QUERY } from '../../../helpers/api/Queries.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js'
import { UPDATE_CLASS_MUTATION } from '../../../helpers/api/Mutation.js';
import { notifyError, notifySuccess, notifyWarning } from '../../../common/notifications.js';
import { setClassTableData } from '../../../redux/common_reducer.js';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import schoolStd from "../../../data/school_std.json"
import schoolSections from "../../../data/school_sections.json"
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


const Example = ({ selectedSchool }) => {
    const classTableData = useSelector((state) => state.common.classTableData)

    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const dispatch = useDispatch();
    const schools = useSelector((state) => state.common.schools);
    const [teacherOptions, setTeacherOptions] = useState([]);
    const [currentTeacher, setCurrentTeacher] = useState(null);

    // Fetch teacher dropdown options According to selected school value
    const fetchData = async (values) => {
        try {
            if (!selectedSchool) {
                setIsLoading(false);
                dispatch(setClassTableData([]));
                return;
            }
            setIsLoading(true);
            const response = await QueryRequest(GET_ALL_CLASS_QUERY, { schoolId: selectedSchool.value });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            dispatch(setClassTableData((response?.data?.getAllClazz?.items)));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedSchool]);


    // Disable the for edit option for school 
    function editOptions() {
        const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
        if (superAdminIdGet) {
            return true
        } else {
            return false
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                hidden: true,
                Edit: () => null,
                size: 80,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'schoolId',
                header: 'school Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'teacherId',
                header: 'Teacher Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'teacher.school.name',
                header: 'School',
                enableEditing: false,
                muiEditTextFieldProps: {
                    required: true,
                    // select: true,
                    error: !!validationErrors?.['teacher.school.name'],
                    helperText: validationErrors?.['teacher.school.name'],
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            ['teacher.school.name']: undefined,
                        }),
                },
                // editSelectOptions: schools

            },
            {
                accessorKey: 'standard',
                header: 'Standard',
                enableEditing: editOptions(),
                muiEditTextFieldProps: {
                    select: true,
                    required: true,
                    error: !!validationErrors?.standard,
                    helperText: validationErrors?.standard,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            standard: undefined,
                        }),
                },
                editSelectOptions: schoolStd
            },
            {
                accessorKey: 'section',
                header: 'Section',
                enableEditing: editOptions(),
                muiEditTextFieldProps: {
                    select: true,
                    required: true,
                    error: !!validationErrors?.section,
                    helperText: validationErrors?.section,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            section: undefined,
                        }),
                },
                editSelectOptions: schoolSections
            },
            {
                accessorKey: 'alias',
                header: 'Alias',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.alias,
                    helperText: validationErrors?.alias,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            alias: undefined,
                        }),
                },
            },
            {
                accessorKey: 'teacher.name',
                header: 'Class Teacher',
                enableEditing: true,
                Edit: ({ row }) => {
                    const teacherName = row.original.teacher.name;
                    const [selectedTeacher, setSelectedTeacher] = useState({ value: teacherName, label: teacherName });

                    const handleTeacherChange = (selectedOption) => {
                        setSelectedTeacher(selectedOption);
                        setCurrentTeacher(selectedOption);
                    };

                    return (
                        <div>
                            <p style={{ color: "GrayText", fontFamily: "sans-serif", fontSize: 12, marginBottom: 10 }}>Class Teacher<span className='pl-2'>*</span></p>
                            <Select
                                value={selectedTeacher}
                                onChange={handleTeacherChange}
                                options={teacherOptions}
                                isSearchable
                                components={makeAnimated()}
                            />
                        </div>
                    );
                },
            },

        ],
        [validationErrors, teacherOptions],
    );

    // Fetch teacher options after selecting the edit button according to row
    const fetchTeacherOptions = async (row) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await QueryRequest(GET_TEACHER_DROPDOWN_QUERY, { schoolId: row.original.schoolId });
            const fetchedTeachers = response.data.getTeacherDropDownOptions.options;

            let schoolsForTeacherToSet = fetchedTeachers.map((teacher) => ({
                value: teacher.value,
                label: teacher.label,
            }));
            setTeacherOptions(schoolsForTeacherToSet);
        } catch (error) {
            console.error('Error fetching teacher options:', error);
        }
    };

    // Handle save user for edit modal and validate the input fields
    const handleSaveUser = async ({ values, table, row }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        try {
            setIsUpdatingUser(true);

            const classId = values.id;
            if (!classId) {
                console.error('class ID is missing.' + classId);
                return;
            }

            function teacherChange() {
                if (currentTeacher === null) {
                    return values.teacherId
                } else {
                    return currentTeacher.value
                }
            }
            const data = {
                standard: values.standard,
                section: values.section,
                alias: values.alias,
                schoolId: values.schoolId,
                teacherId: teacherChange()
            }

            const updateResponse = await MutateRequest(UPDATE_CLASS_MUTATION, {
                id: classId,
                data,
            });
            const response = updateResponse.data.updateOneClazz.status

            if (response === "Failure") {
                notifyError("Invalid creditails")
                return;
            } else if (response === "Success") {
                notifySuccess('The class has been successfully edited');
                await fetchData();
            };
            table.setEditingRow(null);
        } catch (error) {
            console.error('Error class school:', error);
        } finally {
            setIsUpdatingUser(false);
        }
    };

    // Delete class

    // const deleteUser = async ({ values, table }) => {
    //     try {
    //         setIsDeletingUser(true);

    //         const classId = values.id;

    //         if (!classId) {
    //             console.error('Class ID is missing or undefined.');
    //             return;
    //         }

    //         const updateResponse = await MutateRequest(DELETE_CLASS_MUTATION, { id: classId });

    //         // Remove the deleted row from the data state
    //         setData(prevData => prevData.filter(row => row.id !== classId));
    //         notifySuccess('The class has been successfully deleted');
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //     } catch (error) {
    //         console.error('Error deleting class:', error);
    //     } finally {
    //         setIsDeletingUser(false);
    //     }
    // }

    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this class?')) {
    //         deleteUser({ values: row, table });
    //     }
    // };

    // Export data
    const handleExportData = () => {
        // If there is no data, notify a warning
        if (classTableData.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Get the current state of classTableData and exclude the 'id' field
        const currentData = classTableData.map(item => ({
            'School': item.school?.name || '',
            'Standard': item.standard || '',
            'Section': item.section || '',
            'Alias': item.alias || '',
            'Class Teacher': item.teacher?.name || '',
        }));

        // Calculate column widths
        const colWidths = {};
        currentData.forEach(row => {
            Object.keys(row).forEach(key => {
                if (!colWidths[key] || colWidths[key] < row[key].length) {
                    colWidths[key] = row[key].length;
                }
            });
        });

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(currentData);

        // Auto-size columns
        worksheet['!cols'] = Object.keys(colWidths).map(key => ({ wch: colWidths[key] + 2 }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'classes');

        // Generate XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Save the XLSX file
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'Classes.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const table = useMaterialReactTable({
        columns,
        data: classTableData,
        initialState: { columnVisibility: { id: false, schoolId: false, teacherId: false } },
        editDisplayMode: 'modal',
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoading
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveUser,
        // optionally customize modal content
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Edit class</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => {
                        table.setEditingRow(row);
                        fetchTeacherOptions(row);
                    }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                sx={{ backgroundColor: 'rgb(30 58 138)' }}

            >
                Export All Data
            </Button>
        ),
        state: {
            isLoading: isLoading,
            isSaving: isUpdatingUser || isDeletingUser,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return <MaterialReactTable table={table} />;
};

const AddClassTable = ({ selectedSchool }) => (
    <Example selectedSchool={selectedSchool} />
);

export default AddClassTable;

const validateRequired = (value) => !!value.length;
function validateUser(user) {
    return {
        ['teacher.school.name']: !validateRequired(user['teacher.school.name'])
            ? 'School name is Required'
            : '',
        standard: !validateRequired(user.standard)
            ? 'standard is Required'
            : '',
        section: !validateRequired(user.section)
            ? 'Section is Required'
            : '',
        alias: !validateRequired(user.alias)
            ? 'Alias is Required'
            : '',
        ['teacher.name']: !validateRequired(user['teacher.name'])
            ? 'Teacher name is Required'
            : '',

    };
}
