import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import AddClassTable from './addClassTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../redux/formReducer.js';
import { notifySuccess, notifyError } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GET_TEACHER_DROPDOWN_QUERY, GET_ALL_CLASS_QUERY } from '../../../helpers/api/Queries.js';
import { CREATE_CLASS_MUTATION } from '../../../helpers/api/Mutation.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js';
import schoolStd from "../../../data/school_std.json"
import schoolSections from "../../../data/school_sections.json"
import { setTeachers, setClassTableData, setIsLoading } from '../../../redux/common_reducer.js';

const AddClass = () => {

    const dispatch = useDispatch();
    const classData = useSelector((state) => state.form.addClass);
    const schools = useSelector((state) => state.common.schools)
    const teachers = useSelector((state) => state.common.teachers)
    const [errors, setErrors] = useState({});
    const [selectedSchool, setSelectedSchool] = useState(null);
    const isLoading = useSelector((state) => state.common.isLoading);

    // Fetch the teacher dropdown while selecting school
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedSchool) {
                    const teacherVariables = { schoolId: selectedSchool.value };
                    const responseTeacher = await QueryRequest(GET_TEACHER_DROPDOWN_QUERY, teacherVariables);
                    const fetchedTeachers = responseTeacher.data.getTeacherDropDownOptions.options;

                    let schoolsForTeacherToSet = fetchedTeachers.map((teacher) => ({
                        value: teacher.value,
                        label: teacher.label,
                    }));
                    dispatch(setTeachers(schoolsForTeacherToSet))
                }
            } catch (error) {
                notifyError(`${error}`);
            }
        };
        fetchData();
    }, [selectedSchool, dispatch]);

    // Validate the form for all fields are required and to validate the regex
    const validateForm = () => {
        const requiredFields = ['selectedSchool', 'selectedStandard', 'selectedSection', 'alias', 'selectedTeacher'];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!classData[field] || (Array.isArray(classData[field]) && !classData[field].length)) {
                newErrors[field] = 'This field is required.';
            } else {
                newErrors[field] = '';
            }
        });

        const emptyFields = requiredFields.filter((field) => !classData[field] || (Array.isArray(classData[field]) && !classData[field].length));
        if (emptyFields.length > 0) {
            notifyError('All fields are required');
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleFieldChange = (fieldName, selectedOption) => {
        setErrors({ ...errors, [fieldName]: '' });

        if (fieldName === 'selectedSchool') {
            dispatch(updateForm({ formName: 'addClass', formData: { selectedTeacher: null, selectedSchool: selectedOption } }));
            setSelectedSchool(selectedOption);
        } else {
            dispatch(updateForm({ formName: 'addClass', formData: { [fieldName]: selectedOption } }));
        }
    };


    //  Clear the all input values and validation erroes

    const handleClear = () => {
        dispatch(clearForm({ formName: 'addClass' }));
        setErrors({});
        notifySuccess("Form cleared successfully")

    };

    // After validate the form handle to save the data through backend
    const handleSave = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            dispatch(setIsLoading(true));
            try {
                const response = await MutateRequest(CREATE_CLASS_MUTATION, {
                    data: {
                        schoolId: classData.selectedSchool.value,
                        standard: classData.selectedStandard.value,
                        section: classData.selectedSection.value,
                        alias: classData.alias,
                        teacherId: classData.selectedTeacher.value,
                    },
                });
                const responseStatus = response.data.createOneClazz.status
                if (responseStatus === "Success") {
                    notifySuccess("Class created successfully");
                    //Reload the Class table as new Class got added successfully 
                    try {
                        const response = await QueryRequest(GET_ALL_CLASS_QUERY, { schoolId: selectedSchool.value });
                        dispatch(setClassTableData((response.data.getAllClazz.items)));
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }

                    dispatch(clearForm({ formName: 'addClass' }));
                    setErrors({});
                } else if (responseStatus === "Failure") {
                    notifyError(response.data.createOneClazz.error.message)
                }

            } catch (error) {
                console.error('Error creating Class:', error);
                notifyError('An error occurred while saving. Please try again.');
            } finally {
                dispatch(setIsLoading(false));
            }
        };

    };



    return (
        <div>
            <ToastContainer />
            <div className='flex flex-row justify-between mb-6'>
                <h2 className="text-2xl font-bold">Add Class</h2>
            </div>
            <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form >
                    <h2 className=' text-xl text-black font-bold mb-3'>Class Detail:</h2>
                    <div className="mb-4 md:flex md:items-center">

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectedSchool ? 'has-error' : ''}`}>
                            <label htmlFor="selectedSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {errors.selectedSchool && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectedSchool ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schools}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={classData.selectedSchool}
                                    onChange={(selectedOption) => handleFieldChange('selectedSchool', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectedStandard ? 'has-error' : ''}`}>
                            <label htmlFor="selectedSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Standard
                                {errors.selectedStandard && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.selectedStandard ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schoolStd}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={classData.selectedStandard}
                                    onChange={(selectedOption) => handleFieldChange('selectedStandard', selectedOption)}
                                />
                            </div>
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.selectedSection ? 'has-error' : ''}`}>
                            <label htmlFor="selectedSection" className="block text-gray-700 text-sm font-bold mb-4">
                                Select Section
                                {errors.selectedSection && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.selectedSection ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={schoolSections}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={classData.selectedSection}
                                    onChange={(selectedOption) => handleFieldChange('selectedSection', selectedOption)}
                                    className='z-50'
                                />
                            </div>
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="alias" className="block text-gray-700 text-sm font-bold mb-4">
                                Alias
                                {errors.alias && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <input
                                type="text"
                                id="alias"
                                name="alias"
                                className={`w-full border p-2 rounded ${errors.alias ? 'border-red-500' : ''}`}
                                onChange={(e) => handleFieldChange('alias', e.target.value)}
                                value={classData.alias}
                            />
                        </div>
                    </div>

                    <div className="mb-4 md:flex md:items-center">
                        <div className={`md:w-1/4 mb-4 md:mb-0  relative ${errors.selectedTeacher ? 'has-error' : ''}`}>
                            <label htmlFor="selectTeacher" className="block text-gray-700 text-sm font-bold mb-4">
                                Class Teacher
                                {errors.selectedTeacher && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.selectedTeacher ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={teachers}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    onChange={(selectedOption) => handleFieldChange('selectedTeacher', selectedOption)}
                                    value={classData.selectedTeacher}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='flex mt-24  mb-24 flex-row space-x-5 justify-end'>
                        <button
                            type="button"
                            onClick={handleClear}
                            className=" lg:px-16 lg:py-2 md:px-10 md:py-0.5 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                            <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                        </button>
                        <button
                            type="submit"
                            onClick={handleSave}
                            className="lg:px-16 lg:py-2 md:py-0.5 md:px-10 sm:px-10 sm:py-1 px-6 py-2 border border-blue-900 text-white bg-blue-900  rounded transition-all ">
                            {isLoading ? (
                                <div className="flex items-center justify-center w-full">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                </div>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faSave} /> Save
                                </>
                            )}
                        </button>

                    </div>

                </form >
                {/* END OF FORM */}
                < hr className="border-t border-gray-300 my-8 mb-28" />
                < AddClassTable selectedSchool={selectedSchool} />
            </div >

        </div >
    );
};

export default AddClass