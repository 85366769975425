import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        isLoading: false,
        schoolID: "",
        TeacherID: "",
        responseFromServer: null,
        classForBulkUpload: [],
        teachers: [],
        schools: [],
        classes: [],
        bulkUploadFile: [],
        schoolTableData: [],
        teacherTableData: [],
        classTableData: [],
        studentTableData: [],
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSchoolID: (state, action) => {
            state.schoolID = action.payload;
        },
        setTeacherID: (state, action) => {
            state.TeacherID = action.payload;
        },
        setStudentTableData: (state, action) => {
            state.studentTableData = action.payload;
        },
        setClassTableData: (state, action) => {
            state.classTableData = action.payload;
        },
        setTeacherTableData: (state, action) => {
            state.teacherTableData = action.payload;
        },
        setSchoolTableData: (state, action) => {
            state.schoolTableData = action.payload;
        },
        setBulkUploadFile: (state, action) => {
            state.bulkUploadFile = action.payload;
        },
        setClassForBulkUpload: (state, action) => {
            state.classForBulkUpload = action.payload;
        },
        setTeachers: (state, action) => {
            state.teachers = action.payload;
        },
        setSchools: (state, action) => {
            state.schools = action.payload;
        },
        setClasses: (state, action) => {
            state.classes = action.payload;
        },
        setResponseFromServer: (state, action) => {
            state.responseFromServer = action.payload;
        },
    },
});


export const { setIsLoading, setTeacherID, setSchoolID, setStudentTableData, setClassTableData, setTeacherTableData, setSchoolTableData, setBulkUploadFile, setClassForBulkUpload, setSchoolsForBulkUpload, setSchools, setClasses, setResponseFromServer, setSchoolsForClass, setTeachers, setSchoolsForTeacher } = commonSlice.actions;

export default commonSlice.reducer;