import { useMemo, useState, useEffect } from 'react';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_ALL_SCHOOL_QUERY } from '../../../helpers/api/Queries.js';
import { UPDATE_SCHOOL_MUTATION } from '../../../helpers/api/Mutation.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js'
import { notifySuccess } from '../../../common/notifications.js';
import { useDispatch, useSelector } from 'react-redux';
import { setSchoolTableData } from '../../../redux/common_reducer.js';
import indianStates from "../../../data/indian_states.json"
import tamilnaduDistricts from "../../../data/tamilnadu_districts.json"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { notifyWarning } from '../../../common/notifications.js';


const Example = () => {
    const schoolTableData = useSelector((state) => state.common.schoolTableData)
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const dispatch = useDispatch();



    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await QueryRequest(GET_ALL_SCHOOL_QUERY);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                dispatch(setSchoolTableData(response?.data?.getAllSchools?.items))
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);



    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'name',
                header: 'School Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                accessorKey: 'address1',
                header: 'Address 1',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.address1,
                    helperText: validationErrors?.address1,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            address1: undefined,
                        }),
                },
            },
            {
                accessorKey: 'address2',
                header: 'Address 2',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.address2,
                    helperText: validationErrors?.address2,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            address2: undefined,
                        }),
                },
            },
            {
                accessorKey: 'district',
                header: 'District',
                muiEditTextFieldProps: {
                    required: true,
                    select: true,
                    error: !!validationErrors?.district,
                    helperText: validationErrors?.district,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            district: undefined,
                        }),
                },
                editSelectOptions: tamilnaduDistricts
            },
            {
                accessorKey: 'state',
                header: 'State',
                muiEditTextFieldProps: {
                    required: true,
                    select: true,
                    error: !!validationErrors?.state,
                    helperText: validationErrors?.state,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            state: undefined,
                        }),
                },
                editSelectOptions: indianStates
            },
            {
                accessorKey: 'pincode',
                header: 'Pincode',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.pincode,
                    helperText: validationErrors?.pincode,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            pincode: undefined,
                        }),
                },
            },
            {
                accessorKey: 'mobileNumber',
                header: 'Phone Number',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.mobileNumber,
                    helperText: validationErrors?.mobileNumber,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            mobileNumber: undefined,
                        }),
                },
            },
            {
                accessorKey: 'email',
                header: 'Email ID',
                muiEditTextFieldProps: {
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.email,
                    helperText: validationErrors?.email,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            email: undefined,
                        }),
                },
            },

        ],
        [validationErrors],
    );

    //UPDATE action for modal to update the school details
    const handleSaveUser = async ({ values, table }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        try {
            setIsUpdatingUser(true);
            const schoolId = values.id;
            if (!schoolId) {
                console.error('School ID is missing.' + schoolId);
                return;
            }
            const data = {
                name: values.name,
                email: values.email,
                mobileNumber: values.mobileNumber,
                address1: values.address1,
                address2: values.address2,
                district: values.district,
                state: values.state,
                pincode: values.pincode
            }

            const updateResponse = await MutateRequest(UPDATE_SCHOOL_MUTATION, {
                id: schoolId,
                data
            });
            const response = updateResponse.data.updateOneSchool.status
            if (response === "Success") {
                notifySuccess('The school has been successfully edited')
            }
            await new Promise((resolve) => setTimeout(resolve, 1000));
            table.setEditingRow(null);
        } catch (error) {
            console.error('Error updating school:', error);
        } finally {
            setIsUpdatingUser(false);
        }
    };


    // Delete school

    // const deleteUser = async ({ values, table }) => {
    //     try {
    //         const schoolId = values.id;
    //         setIsDeletingUser(true);
    //         if (!schoolId) {
    //             console.error('School ID is missing or undefined.');
    //             return;
    //         }

    //         const updateResponse = await MutateRequest(DELETE_SCHOOL_MUTATION, { id: schoolId });

    //         // Remove the deleted row from the data state
    //         // setData(prevData => prevData.filter(row => row.id !== schoolId));
    //         notifySuccess('The school has been successfully deleted');
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //     } catch (error) {
    //         console.error('Error deleting school:', error);
    //     } finally {
    //         setIsDeletingUser(false);
    //     }
    // }




    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this school?')) {
    //         deleteUser({ values: row, table });
    //     }
    // };
    const handleExportData = () => {
        // If there is no data, notify a warning
        if (!schoolTableData || schoolTableData.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(schoolTableData);

        // Calculate column widths
        const colWidths = {};
        schoolTableData.forEach(row => {
            Object.keys(row).forEach(key => {
                if (!colWidths[key] || colWidths[key] < String(row[key]).length) {
                    colWidths[key] = String(row[key]).length;
                }
            });
        });

        // Auto-size columns
        worksheet['!cols'] = Object.keys(colWidths).map(key => ({ wch: colWidths[key] + 2 }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Save the XLSX file
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'Schools.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };



    // Render table with data and custom
    const table = useMaterialReactTable({
        columns,
        data: schoolTableData,
        editDisplayMode: 'modal',
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        enableEditing: true,
        initialState: { columnVisibility: { id: false } },
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoading
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveUser,
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Edit School</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                sx={{ backgroundColor: 'rgb(30 58 138)' }}

            >
                Export All Data
            </Button>
        ),
        state: {
            isLoading: isLoading,
            isSaving: isUpdatingUser || isDeletingUser,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return <MaterialReactTable table={table} />;
};


const AddSchoolTable = () => (
    <Example />
);

export default AddSchoolTable;


// Regex and validation required 
const validateRequired = (value) => !!value.length;
const validateEmail = (email) => !!email.length && email
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

const validatePhoneNumber = (phoneNumber) => !!phoneNumber.length && phoneNumber.match(/^\d{10}$/);
const validatePincode = (pincode) => !!pincode.length && pincode.match(/^\d{6}$/);


// Validate the edit modal if the fields are empty and validate the regex
function validateUser(user) {
    const errors = {
        name: !validateRequired(user.name) ? 'School Name is Required' : '',
        address1: !validateRequired(user.address1) ? 'Address 1 is Required' : '',
        address2: !validateRequired(user.address2) ? 'Address 2 is Required' : '',
        district: !validateRequired(user.district) ? 'District is Required' : '',
        state: !validateRequired(user.state) ? 'State is Required' : '',
        pincode: !validateRequired(user.pincode)
            ? 'Phone Number is Required'
            : !validatePincode(user.pincode)
                ? 'Invalid Phone Number'
                : '',
        mobileNumber: !validateRequired(user.mobileNumber)
            ? 'Phone Number is Required'
            : !validatePhoneNumber(user.mobileNumber)
                ? 'Invalid Phone Number'
                : '',
        email: !validateRequired(user.email)
            ? 'Email is Required'
            : !validateEmail(user.email)
                ? 'Invalid Email'
                : '',
    };

    return errors;
}