import { useMemo, useState, useEffect } from 'react';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    // createRow,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    TextField,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_ALL_TEACHER_QUERY } from '../../../helpers/api/Queries.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js'
import { UPDATE_TEACHER_MUTATION } from '../../../helpers/api/Mutation.js';
import { notifySuccess, notifyWarning } from '../../../common/notifications.js';
import { useDispatch, useSelector } from 'react-redux';
import { setTeacherTableData } from '../../../redux/common_reducer.js';
import specialization from "../../../data/teachersSpecialization.json";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';

const Example = ({ selectedSchool }) => {
    const dispatch = useDispatch();
    const teacherTableData = useSelector((state) => state.common.teacherTableData)
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [selectedSchoolValue, setSelectedSchoolValue] = useState("");
    const schools = useSelector((state) => state.common.schools);

    // Fetch Teacher table according to selected school
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                // If no school is selected, set loading to false and studentTableData to an empty array
                if (!selectedSchool) {
                    setIsLoading(false);
                    dispatch(setTeacherTableData([]));
                    return;
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
                const response = await QueryRequest(GET_ALL_TEACHER_QUERY, { schoolId: selectedSchool.value });
                dispatch(setTeacherTableData(response?.data?.getAllTeachers?.items));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, selectedSchool]);


    // Enable the edit option for superadmin 
    function editOptions() {
        const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
        if (superAdminIdGet) {
            return true
        } else {
            return false
        }
    }

    // Update the selectedSchoolValue state when a new value is selected
    const handleSchoolSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedSchoolValue(newValue);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'schoolId',
                header: 'school Id',
                enableEditing: false,
                hidden: true,
                size: 80,
                Edit: () => null,
                visibleInShowHideMenu: false
            },
            {
                accessorKey: 'school.name',
                header: 'School Name',
                enableEditing: false,
                muiEditTextFieldProps: {
                    select: true,
                    required: true,
                    error: !!validationErrors?.['school.name'],
                    helperText: validationErrors?.['school.name'],
                    onFocus: () =>
                        setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            school: {
                                ...prevErrors?.school,
                                ['school.name']: undefined,
                            },
                        })),
                    value: selectedSchoolValue,
                    onChange: handleSchoolSelectChange
                },
                editSelectOptions: schools
            },
            {
                accessorKey: 'name',
                header: 'Teacher’s Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                accessorKey: 'specialization',
                header: 'Specialization',
                muiEditTextFieldProps: {
                    required: true,
                    select: true,
                    error: !!validationErrors?.specialization,
                    helperText: validationErrors?.specialization,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            specialization: undefined,
                        }),
                },
                editSelectOptions: specialization
            },
            {
                accessorKey: 'mobileNumber',
                header: 'Phone No',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.mobileNumber,
                    helperText: validationErrors?.mobileNumber,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            mobileNumber: undefined,
                        }),
                },
            },
            {
                accessorKey: 'email',
                header: 'Email ID',
                enableEditing: editOptions(),
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.email,
                    helperText: validationErrors?.email,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            email: undefined,
                        }),
                },
            },
        ],
        [validationErrors, schools, selectedSchoolValue],
    );

    // UPDATE action
    // After validation Update the edit modal and to save the user
    const handleSaveUser = async ({ values, table }) => {
        const newValidationErrors = validateTeacher(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});

        try {
            setIsUpdatingUser(true);
            const data = {
                email: values.email,
                name: values.name,
                mobileNumber: values.mobileNumber,
                specialization: values.specialization,
                schoolId: values.schoolId,
            }   
            const teacherId = values.id
            const updateResponse = await MutateRequest(UPDATE_TEACHER_MUTATION, {
                id: teacherId,
                data
            });
            const response = updateResponse.data.updateOneTeacher.status

            if (response === "Success") {
                notifySuccess('The teacher has been successfully edited');
            }
            await new Promise((resolve) => setTimeout(resolve, 1000));
            table.setEditingRow(null);
        } catch (error) {
            console.error('Error updating school:', error);
        } finally {
            setIsUpdatingUser(false);
        }
    };

    // Delete teacher

    // const deleteUser = async ({ values, table }) => {
    //     try {
    //         setIsDeletingUser(true);

    //         const teacherID = values.id;

    //         if (!teacherID) {
    //             console.error('Teacher ID is missing or undefined.');
    //             return;
    //         }

    //         const updateResponse = await MutateRequest(DELETE_TEACHER_MUTATION, { id: teacherID });

    //         // Remove the deleted row from the data state
    //         setData(prevData => prevData.filter(row => row.id !== teacherID));
    //         notifySuccess('The Teacher has been successfully deleted');
    //         await new Promise((resolve) => setTimeout(resolve, 1000));
    //     } catch (error) {
    //         console.error('Error deleting teacher:', error);
    //     } finally {
    //         setIsDeletingUser(false);
    //     }
    // }


    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //     if (window.confirm('Are you sure you want to delete this school?')) {
    //         deleteUser({ values: row, table });
    //     }
    // };

    const handleExportData = () => {
        // If there is no data, notify a warning
        if (teacherTableData.length === 0) {
            notifyWarning("No data to export");
            return;
        }

        // Get the current state of teacherTableData and exclude the 'id' field
        const currentData = teacherTableData.map(teacher => ({
            'School Name': teacher.school?.name || '',
            'Teacher’s Name': teacher.name || '',
            'Specialization': teacher.specialization || '',
            'Phone No': teacher.mobileNumber || '',
            'Email ID': teacher.email || '',
        }));

        // Convert the data to XLSX format
        const worksheet = XLSX.utils.json_to_sheet(currentData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Teachers');

        // Generate XLSX file
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Save the XLSX file
        saveAs(new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' }), 'teachers.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };



    const table = useMaterialReactTable({
        columns,
        data: teacherTableData,
        editDisplayMode: 'modal',
        enableEditing: true,
        muiTablePaperProps: ({ table }) => ({
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        initialState: { columnVisibility: { id: false, schoolId: false } },
        getRowId: (row) => row.id,
        emptyStateMessage: 'No teachers available',
        muiToolbarAlertBannerProps: isLoading
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveUser,


        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h3">Edit User</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="Delete" onClick={() => openDeleteConfirmModal(row)} >
                    <IconButton color="error" >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                sx={{ backgroundColor: 'rgb(30 58 138)' }}

            >
                Export All Data
            </Button>
        ),
        state: {
            isLoading: isLoading,
            isSaving: isUpdatingUser || isDeletingUser,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return <MaterialReactTable table={table} />;
};

const AddSchoolTable = ({ selectedSchool }) => (
    <Example selectedSchool={selectedSchool} />
);

export default AddSchoolTable;

const validateRequired = (value) => !!value.length;
const validatePhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);
const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Validation for all fields are required 
function validateTeacher(user) {
    return {
        ['school.name']: !validateRequired(user['school.name'])
            ? 'School name is Required'
            : '',
        name: !validateRequired(user.name)
            ? 'Teacher Name is Required'
            : '',
        specialization: !validateRequired(user.specialization)
            ? 'Specialization is Required'
            : '',
        mobileNumber: !validateRequired(user.mobileNumber)
            ? 'Phone Number is Required'
            : !validatePhoneNumber(user.mobileNumber)
                ? 'Invalid Phone Number'
                : '',
        email: !validateRequired(user.email)
            ? 'Email is Required'
            : !validateEmail(user.email)
                ? 'Invalid Email'
                : '',
    };
}
