import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-feather';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import 'react-datepicker/dist/react-datepicker.css';
import AddStudentFliterTable from './addStudentFliterTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm, clearFormAll } from '../../../redux/formReducer.js';
import { setResponseFromServer, setClasses } from '../../../redux/common_reducer.js';
import { notifySuccess, notifyError } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js';
import { GET_CLASS_DROPDOWN_QUERY, VALIDATE_PARENT_KID_MUTATION, GET_ALL_STUDENTS_QUERY } from '../../../helpers/api/Queries.js';
import { CREATE_PARENT_KID_MUTATION } from '../../../helpers/api/Mutation.js';
import moment from 'moment';
import ModalPopup from '../../../common/mobileConfirmationModal.js';
import BulkUpload from './bulkUploadStudent/bulkUploadStudent.js';
import { setStudentTableData } from '../../../redux/common_reducer.js';
import { getItemFromLocalStorage } from '../../../helpers/api/utils.js';

const AddStudentForm = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.form.addStudent);
    const schools = useSelector((state) => state.common.schools);
    const classes = useSelector((state) => state.common.classes);

    const [validationErrors, setValidationErrors] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [uploadMode, setUploadMode] = useState('manual');

    // Fetch classes for dropdown after selected value
    const fetchClasses = async (selectedSchoolValue) => {
        try {
            // Before loading student table, check if logged in a teacher if so pass techerId as queryParam else send schoolId
            // This condition is retrieve students based on the loged in user role
            const teacherIdGet = getItemFromLocalStorage('teacher_id');
            const queryParam = {};
            if (teacherIdGet) {
                queryParam.teacherId = teacherIdGet.id
            } else {
                queryParam.schoolId = selectedSchoolValue
            }
            const responseClass = await QueryRequest(GET_CLASS_DROPDOWN_QUERY, queryParam);
            const fetchedClass = responseClass.data.getClazzDropDownOptions.options;

            // Set the classes for dropdown
            dispatch(setClasses(
                fetchedClass.map((clazz) => ({
                    value: clazz.value,
                    label: clazz.label,
                }))
            ));
        } catch (error) {
            notifyError(`${error}`);
        }
    };

    const handleSchoolChange = (selectedOption) => {
        setSelectedSchool(selectedOption);
        dispatch(updateForm({ formName: 'addStudent', formData: { selectedSchool: selectedOption } }));
        setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith('All fields are required')));

        if (selectedOption && selectedOption.value) {
            fetchClasses(selectedOption.value);
        } else {
            dispatch(setClasses([]));
        }
    };


    const handleParentChange = (field, value) => {
        setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith(`Parent ${field}`)));
        dispatch(updateForm({
            formName: 'addStudent',
            formData: {
                parentDetails: {
                    ...formData.parentDetails,
                    [field]: value,
                },
            },
        }));
        setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith(`Parent ${field}`)));

    };

    const handleStudentChange = (index, field, value) => {
        const updatedStudents = [...formData.students];
        updatedStudents[index] = {
            ...updatedStudents[index],
            [field]: value,
        };

        dispatch(updateForm({
            formName: 'addStudent',
            formData: {
                students: updatedStudents,
            },
        }));

        setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith(`Student ${index + 1} ${field}`)));
    };


    const handleDobChange = (index, date) => {
        // Get the DOB in this format DD/MM/YYYY
        const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
        const updatedStudents = [...formData.students];
        updatedStudents[index] = {
            ...updatedStudents[index],
            dob: formattedDate,
        };

        dispatch(updateForm({
            formName: 'addStudent',
            formData: {
                students: updatedStudents,
            },
        }));
    };

    // Clear the all input values and validation erroes
    const handleClear = () => {
        dispatch(clearForm({ formName: 'addStudent' }));
        setValidationErrors([]);
        notifySuccess("Form cleared successfully")
    };

    const phoneRegex = /^\d{10}$/;
    const pincodeRegex = /^\d{6}$/;
    const nameRegex = /^[A-Za-z. ]*$/

    const validateForm = () => {
        const errors = [];
        // validate the students of array 
        formData.students.forEach((student, index) => {
            if (!student.studentName || !student.gender || !student.dob || !student.studentClass) {
                errors.push(`Student ${index + 1} required`);
            } else if (!nameRegex.test(student.studentName)) {
                errors.push('Student name should be valid alphabets with space and dot(.) only');
                notifyError('Student name should be valid alphabets with space and dot(.) only');
            }
        });

        // Validation for school and parents details
        if (!formData.selectedSchool || !formData.parentDetails.parentName || !formData.parentDetails.parentPhoneNumber ||
            !formData.parentDetails.parentPincode
        ) {
            notifyError('All fields are required');
            errors.push('All fields are required');
        } else {
            if (!nameRegex.test(formData.parentDetails.parentName)) {
                errors.push('Parent name should be valid alphabets with space and dot(.) only');
                notifyError('Parent name should be valid alphabets with space and dot(.) only');
            }
            if (!phoneRegex.test(formData.parentDetails.parentPhoneNumber)) {
                errors.push('Invalid phone number');
                notifyError('Invalid phone number');
            }

            if (!pincodeRegex.test(formData.parentDetails.parentPincode)) {
                errors.push('Invalid pin code');
                notifyError('Invalid pin code');
            }
        }

        setValidationErrors(errors);
        return errors.length === 0;
    };
    const [isInputListHasValidationErrors, setIsInputListHasValidationErrors] = useState(false);
    const responseFromServer = useSelector(state => state.common.responseFromServer);

    // If there is no validation errors call is function for submit
    const handleSubmit = async (responseItem) => {
        try {
            let items = [];
            const requestData = responseItem.map(item => {
                const pincode = item.pincode;
                return {
                    sno: 1,
                    parentName: item.parentName,
                    mobileNumber: item.mobileNumber,
                    pincode: pincode,
                    kids: item.kids.map(kid => ({
                        clazzId: kid.clazzId,
                        studentName: kid.studentName,
                        gender: kid.gender,
                        dob: kid.dob
                    }))
                };
            });
            items.push(requestData);
            const response = await MutateRequest(CREATE_PARENT_KID_MUTATION, {
                data: { items: requestData }
            });

            if (response.data.createSchoolParentKid.error) {
                console.error(response.data.createSchoolParentKid.error.message);
                notifyError(response.data.createSchoolParentKid.error.message, { autoClose: 8000 })
                return;
            } else if (response.data.createSchoolParentKid.error === null) {
                notifySuccess("Student created successfully");
                dispatch(clearForm({ formName: 'addStudent' }));
                setValidationErrors([]);
                // Reload the student table as new student got added successfully
                // After adding the student condition is retrieve students based on the loged in user role
                const teacherIdGet = getItemFromLocalStorage('teacher_id');
                const queryParam = {};
                if (teacherIdGet) {
                    queryParam.teacherId = teacherIdGet.id
                } else {
                    queryParam.schoolId = formData.selectedSchool.value
                }
                try {
                    const response = await QueryRequest(GET_ALL_STUDENTS_QUERY, queryParam);
                    dispatch(setStudentTableData(response?.data?.getStudents?.items))
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

        } catch (error) {
            console.error('Error sending data to backend:', error);
            notifyError('An error occurred while sending data.');
        }
    };

    // Handle validation (from backend) before submitting the form 
    const handleValidation = async () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            try {
                let items = [];
                const requestData = {
                    sno: 1,
                    parentName: formData.parentDetails.parentName,
                    mobileNumber: formData.parentDetails.parentPhoneNumber,
                    pincode: formData.parentDetails.parentPincode,
                    kids: formData.students.map(student => ({
                        clazzId: student.studentClass.value,
                        studentName: student.studentName,
                        gender: student.gender,
                        dob: student.dob
                    }))
                };
                // Request data which is sending to backend
                items.push(requestData);
                const response = await QueryRequest(VALIDATE_PARENT_KID_MUTATION, {
                    data: { items: items }
                });
                const isInputListHasValidationErrors = response.data.validateCreateParentKid.isInputListHasValidationErrors
                const responseItem = response.data.validateCreateParentKid.items;
                dispatch(setResponseFromServer(responseItem));

                // Iterate the responce (isInputListHasValidationErrors) for validation modal open or to submit the responce 
                responseItem.forEach(item => {
                    const existingParentId = item.existingParentId;
                    if (existingParentId != null && !isInputListHasValidationErrors) {
                        setModalOpen(true);
                        setIsInputListHasValidationErrors(false)
                    }
                    else if (isInputListHasValidationErrors) {
                        setIsInputListHasValidationErrors(true)
                        setModalOpen(true);
                    } else {
                        handleSubmit(responseItem);
                    }

                });
            } catch (error) {
                console.error('Error creating kid:', error);
                notifyError('An error occurred while saving. Please try again.');
            }
        }
    };

    // Toggle manual and bulk upload
    const toggleUploadMode = (mode) => {
        setUploadMode(mode);
        dispatch(clearForm({ formName: 'addStudent' }));
        setValidationErrors([]);
    };

    // Delete if the student was added 
    const deleteStudent = (index) => {
        const updatedStudents = [...formData.students];
        updatedStudents.splice(index, 1);
        dispatch(updateForm({
            formName: 'addStudent',
            formData: {
                students: updatedStudents,
            },
        }));
    };

    // Add student for same parents 
    const addStudent = () => {
        const updatedStudents = [...formData.students, { studentName: '', gender: '', dob: null, studentClass: '' }];
        dispatch(updateForm({ formName: 'addStudent', formData: { students: updatedStudents } }));
    };

    // Handle modal to close the function
    const handleClose = () => {
        setModalOpen(false);
    };

    // Display the option dropdown for gender
    const gender = ['Male', 'Female', 'Other']

    return (
        <div>
            <div>
                <ToastContainer />
                <ModalPopup open={modalOpen} setOpen={setModalOpen} handleClose={handleClose} isAgreeButtonDisabled={isInputListHasValidationErrors} />
            </div>
            <div className=' mb-6'>
                <h2 className="text-2xl font-bold">Add Student</h2>
            </div>
            <button onClick={() => toggleUploadMode('manual')} className={`p-2 px-8 border-blue-900 ${uploadMode === 'manual' ? 'bg-blue-900 text-white' : ''}`}>
                Manual Entry
            </button>
            <button onClick={() => toggleUploadMode('bulk')} className={`p-2 px-8 border-blue-900 ${uploadMode === 'bulk' ? 'bg-blue-900 text-white' : ''}`}>
                Bulk Upload
            </button>
            <hr className='mb-3' />

            {uploadMode === 'manual' && (
                <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                    <form >
                        <h2 className=' text-xl text-black font-bold mb-3'>School Detail:</h2>
                        <div className="md:w-1/4 mb-4 md:mb-0 me-4 ">
                            <label htmlFor="selectSchool" className="block text-gray-700 text-sm font-bold mb-4">
                                Select School
                                {validationErrors.length > 0 && !formData.selectedSchool && (
                                    <span className='text-red-500 pl-2'>*</span>
                                )}
                            </label>
                            <Select
                                options={schools}
                                isSearchable
                                isClearable
                                className={`w-full z-50 border mb-3 rounded ${validationErrors.length > 0 && !formData.selectedSchool ? 'border-red-500' : ''}`}
                                components={makeAnimated()}
                                value={formData.selectedSchool}
                                onChange={handleSchoolChange}
                            />
                        </div>



                        <h2 className=' text-xl text-black font-bold mb-3'>Parent’s Detail:</h2>
                        <div className="mb-4 md:flex md:items-center">
                            <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                <label htmlFor="parentName" className="block text-gray-700 text-sm font-bold mb-4">
                                    Name
                                    {validationErrors.length > 0 && !formData.parentDetails.parentName && (
                                        <span className='text-red-500 pl-2'>*</span>
                                    )}
                                </label>
                                <input
                                    type="text"
                                    id={'parentName'}
                                    name={'parentName'}
                                    className={`w-full border p-2 rounded ${validationErrors.length > 0 && !formData.parentDetails.parentName ? 'border-red-500' : ''}`}
                                    value={formData.parentDetails.parentName}
                                    onChange={(e) => handleParentChange('parentName', e.target.value)}
                                    onFocus={() => setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith('Parent parentName')))}
                                />
                            </div>


                            <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                <label htmlFor="parentPhoneNumber" className="block text-gray-700 text-sm font-bold mb-4">
                                    Phone Number
                                    {validationErrors.length > 0 && !formData.parentDetails.parentPhoneNumber && (
                                        <span className='text-red-500 pl-2'>*</span>
                                    )}
                                </label>
                                <input
                                    type="tel"
                                    maxLength={10}
                                    id={`parentPhoneNumber`}
                                    name={`parentPhoneNumber`}
                                    className={`w-full border p-2 rounded ${validationErrors.length > 0 && !formData.parentDetails.parentPhoneNumber ? 'border-red-500' : ''}`}
                                    value={formData.parentDetails.parentPhoneNumber}
                                    onChange={(e) => handleParentChange('parentPhoneNumber', e.target.value)}
                                    onFocus={() => setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith('Parent parentPhoneNumber')))}
                                />
                            </div>
                            <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                <label htmlFor="parentPincode" className="block text-gray-700 text-sm font-bold mb-4">
                                    Pincode
                                    {validationErrors.length > 0 && !formData.parentDetails.parentPincode && (
                                        <span className='text-red-500 pl-2'>*</span>
                                    )}
                                </label>
                                <input
                                    type="text"
                                    maxLength={6}
                                    id={`parentPincode`}
                                    name={`parentPincode`}
                                    className={`w-full border p-2 rounded ${validationErrors.length > 0 && !formData.parentDetails.parentPincode ? 'border-red-500' : ''}`}
                                    value={formData.parentDetails.parentPincode}
                                    onChange={(e) => handleParentChange('parentPincode', e.target.value)}
                                    onFocus={() => setValidationErrors(prevErrors => prevErrors.filter(error => !error.startsWith('Parent parentPincode')))}
                                />
                            </div>

                        </div>

                        <h2 className=' text-xl text-black font-bold mb-3'>Student Detail:</h2>

                        {formData.students.map((student, index) => (
                            <div key={index} className='mb-16'>
                                <div className="mb-4 md:flex md:items-center">
                                    <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                        <label htmlFor={`studentName${index}`} className="block text-gray-700 text-sm font-bold mb-2     ">
                                            Name
                                            {validationErrors[index] && !student.studentName && (
                                                <span className='text-red-500 pl-2'>*</span>
                                            )}
                                        </label>
                                        <input
                                            type="text"
                                            id={`studentName${index}`}
                                            name={`studentName${index}`}
                                            value={student.studentName}
                                            className={`w-full border p-2 rounded ${validationErrors.length > 0 && !formData.students[index].studentName ? 'border-red-500' : ''}`}
                                            onChange={(e) => handleStudentChange(index, 'studentName', e.target.value)}
                                        />
                                    </div>

                                    <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                        <label htmlFor={`gender${index}`} className="block text-gray-700 text-sm font-bold mb-2">
                                            Gender
                                            {validationErrors[index] && !student.gender && (
                                                <span className='text-red-500 pl-2'>*</span>
                                            )}
                                        </label>
                                        <select
                                            id={`gender${index}`}
                                            name={`gender${index}`}
                                            value={student.gender}
                                            onChange={(e) => handleStudentChange(index, 'gender', e.target.value)}
                                            className={`w-full border p-2 bg-white rounded ${validationErrors.length > 0 && !formData.students[index].gender ? 'border-red-500' : ''}`}
                                        >
                                            <option value="" disabled>Select Gender</option>
                                            {gender.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                    <div className=" mb-4 md:mb-0 me-4">
                                        <div className="relative mb-4 md:mb-0">
                                            <label htmlFor={`dob${index}`} className="block text-gray-700 text-sm font-bold mb-2">
                                                Date of Birth
                                                {validationErrors[index] && !student.dob && (
                                                    <span className='text-red-500 pl-2'>*</span>
                                                )}
                                            </label>
                                            <div className={`flex border rounded-md ${validationErrors.length > 0 && !formData.students[index].dob ? 'border-red-500' : ''}`}>
                                                <DatePicker
                                                    id={`dob${index}`}
                                                    selected={student.dob ? moment(student.dob, 'DD-MM-YYYY').toDate() : null}
                                                    onChange={(date) => handleDobChange(index, date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showTimeSelect={false}
                                                    maxDate={new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate())} // Less than 3 years ago
                                                    minDate={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())} // 18 years ago
                                                    placeholderText="DD/MM/YYYY"
                                                    icon={<Calendar size={20} />}
                                                    showIcon
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown={true}
                                                    yearDropdownItemNumber={20}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    className="w-full border mr-2 rounded"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                                        <label htmlFor={`studentClass${index}`} className="block text-gray-700 text-sm font-bold mb-2">
                                            Student Class
                                            {validationErrors[index] && !student.studentClass && (
                                                <span className='text-red-500 pl-2'>*</span>
                                            )}
                                        </label>
                                        <Select
                                            options={classes}
                                            isSearchable
                                            isClearable
                                            className={`w-full border z-50 rounded ${validationErrors.length > 0 && !formData.students[index].studentClass ? 'border-red-500' : ''}`}
                                            components={makeAnimated()}
                                            value={formData.students[index].studentClass}
                                            onChange={(selectedOption) => handleStudentChange(index, 'studentClass', selectedOption)}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                        />
                                    </div>
                                    {/* Conditionally render delete button if index is not 0 and student has an ID */}
                                    {index !== 0 && (
                                        <FontAwesomeIcon className='cursor-pointer mt-5 ml-4 size-5 text-red-500' onClick={() => deleteStudent(index)} icon={faTrash} />
                                    )}
                                </div>


                                <div key={index} className="flex flex-row space-x-5 justify-end ">
                                    {index === formData.students.length - 1 && (
                                        <div className="mt-6">
                                            <h4
                                                type="button"
                                                onClick={addStudent}
                                                className="bg-white cursor-pointer text-blue-900 px-4 py-2 rounded mr-2"
                                            >
                                                + Add Student
                                            </h4>
                                        </div>
                                    )}
                                </div>
                                {/* {validationErrors.length > 0 && (
                            <div className="text-red-500 mb-4">
                                {validationErrors.map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </div>
                             )}  */}
                            </div>
                        ))
                        }
                        <div className='flex flex-row space-x-5 justify-end'>
                            <button
                                type="button"
                                onClick={handleClear}
                                className=" lg:px-16 lg:py-2 md:px-10 md:py-0.5 sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all hover:text-white hover:bg-blue-900">
                                <FontAwesomeIcon icon={faTimes} size="lg" />Clear
                            </button>
                            <button
                                type="button"
                                onClick={handleValidation}
                                className="lg:px-16 lg:py-2 md:py-0.5 md:px-10 sm:px-10 sm:py-1 px-6 py-2 border border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">
                                <FontAwesomeIcon icon={faSave} /> Save
                            </button>

                        </div>
                    </form >
                    {/* END OF FORM */}
                    < hr className="border-t border-gray-300 my-8 mb-28" />


                    {/* <ApplyFilterTable /> */}
                    < AddStudentFliterTable selectedSchool={selectedSchool} />

                </div >
            )}

            {uploadMode === 'bulk' && (<BulkUpload />)}
        </div >
    );
};

export default AddStudentForm