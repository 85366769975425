// store.js
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import commonReducer from './common_reducer';
import formReducer from './formReducer';
import { thunk } from 'redux-thunk';

const store = configureStore({
  reducer: {
    common: commonReducer,
    form: formReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
});

export default store;
