// reducers/formReducer.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addSchool: {
    schoolName: '',
    address1: '',
    address2: '',
    district: null,
    state: null,
    pincode: '',
    phoneNumber: '',
    emailID: '',
    schoolIcon: null
  },
  addTeacher: {
    selectSchool: null,
    teacherName: '',
    specialization: null,
    phoneNumber: '',
    emailID: '',

  },
  addClass: {
    selectedSchool: null,
    selectedStandard: null,
    selectedSection: null,
    alias: '',
    selectedTeacher: null,
  },
  bulkUpload: {
    selectedSchool: null,
    selectedStandard: null,
    fileUpload: null
  },
  addStudent: {
    selectedSchool: null,
    parentDetails: {
      parentName: '',
      parentPhoneNumber: '',
      parentPincode: '',
    },
    students: [
      { studentName: '', gender: '', dob: null, studentClass: null },
    ],
  },

  progressReport: {
    selectSchool: null,
    class: null,
    fromdate: null,
    todate: null,
    reportType: null
  },

  progressReportChart: {
    selectSchool: null,
    selectStudent: null,
    class: null,
    selectType: null,
    fromdate: null,
    todate: null,
  },

  sendSMS: {
    selectSchool: null,
    class: null,
    SMStypes: null,
    SMSStatus: null
  }

};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { formName, formData } = action.payload;
      state[formName] = { ...state[formName], ...formData };
    },
    clearForm: (state, action) => {
      const { formName } = action.payload;
      state[formName] = { ...initialState[formName] };
    },
    clearFormAll: (state, action) => {
      const { formNames } = action.payload;
      formNames.forEach(formName => {
        state[formName] = { ...initialState[formName] };
      });
    },
    saveResponseData: (state, action) => {
      const { formName, responseData } = action.payload;
      state[formName].responseData = responseData;
    },
  },

});

export const { clearFormAll, updateForm, clearForm, saveResponseData } = formSlice.actions;
export default formSlice.reducer;
