import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import tamilNaduDistrictsData from '../../../data/tamilnadu_districts.json';
import indianStatesData from '../../../data/indian_states.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import AddSchoolTable from './addSchoolTable.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm, clearForm } from '../../../redux/formReducer.js';
import { notifySuccess, notifyError } from "../../../common/notifications.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CREATE_SCHOOL_MUTATION } from '../../../helpers/api/Mutation.js';
import { MutateRequest, QueryRequest } from '../../../helpers/api/service_helper.js';
import { setSchools, setIsLoading } from '../../../redux/common_reducer.js';
import { GET_SCHOOL_DROPDOWN_QUERY, GET_ALL_SCHOOL_QUERY } from '../../../helpers/api/Queries.js';
import { setSchoolTableData } from '../../../redux/common_reducer.js';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const AddSchool = () => {
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const schools = useSelector((state) => state.common.schools)
    const schoolData = useSelector((state) => state.form.addSchool);
    const isLoading = useSelector((state) => state.common.isLoading);
    const formElement = document.getElementById('addSchool');

    const handleChange = (fieldName, value) => {
        setErrors({ ...errors, [fieldName]: '' });
        dispatch(updateForm({ formName: 'addSchool', formData: { [fieldName]: value } }));
    };

    // Validate the form if the field is empty and validate regex
    const validateForm = () => {
        const requiredFields = ['schoolName', 'address1', 'address2', 'district', 'state', 'pincode', 'phoneNumber', 'emailID', 'schoolIcon'];
        const newErrors = {};

        // Validation for required fields
        requiredFields.forEach((field) => {
            if (!schoolData[field] || (Array.isArray(schoolData[field]) && !schoolData[field].length)) {
                newErrors[field] = 'This field is required.';
            } else {
                newErrors[field] = '';
            }
        });

        if (schoolData.schoolName && !/^[a-zA-Z. ]+$/.test(schoolData.schoolName)) {
            newErrors.schoolName = 'Invalid School name';
            notifyError("School name should be valid alphabets with space and dot(.) only");
        }
        // Pincode validation
        if (schoolData.pincode && !/^\d{6}$/.test(schoolData.pincode)) {
            newErrors.pincode = 'Invalid pincode';
            notifyError("Please enter valid pincode");
        }

        // Phone number validation
        if (schoolData.phoneNumber && !/^\d{10}$/.test(schoolData.phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number. Please enter a 10-digit number.';
            notifyError("Invalid phone number. Please enter a 10-digit number.");
        }

        // Email validation
        if (schoolData.emailID && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(schoolData.emailID)) {
            newErrors.emailID = 'Invalid email address.';
            notifyError("Invalid email address.");
        }

        // Check if any required field is empty
        const emptyFields = requiredFields.filter((field) => !schoolData[field] || (Array.isArray(schoolData[field]) && !schoolData[field].length));
        if (emptyFields.length > 0) {
            notifyError('All fields are required');
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                handleChange('schoolIcon', base64String);
            };
            reader.onerror = () => {
                console.error('Error reading the image file');
            };
            reader.readAsDataURL(file);
        }
    };

    // Clear the input values and errors
    const handleClear = async () => {
        dispatch(clearForm({ formName: 'addSchool' }));
        setErrors({});
        formElement.reset();
        notifySuccess("Form cleared successfully");

    };

    // After validate the form the data to be posted
    const handleSave = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(setIsLoading(true));
                const variable = {
                    name: schoolData.schoolName,
                    email: schoolData.emailID,
                    mobileNumber: schoolData.phoneNumber,
                    address1: schoolData.address1,
                    address2: schoolData.address2,
                    district: schoolData.district.label,
                    state: schoolData.state.key,
                    pincode: schoolData.pincode,
                    logo: schoolData.schoolIcon,
                }
                const response = await MutateRequest(CREATE_SCHOOL_MUTATION, {
                    data: variable,
                });
                const responseStatus = response.data.createOneSchool.status
                if (responseStatus === "Success") {
                    const createdSchool = schoolData.schoolName;
                    try {
                        // Reload the school drop data as new school got added successfully
                        const schoolVariables = {};
                        const responseSchool = await QueryRequest(GET_SCHOOL_DROPDOWN_QUERY, schoolVariables);
                        const fetchedSchools = responseSchool.data.getSchoolDropDownOptions.options;
                        dispatch(setSchools(
                            fetchedSchools.map((school) => ({
                                value: school.value,
                                label: school.label,
                            }))
                        ));
                    } catch (error) {
                        notifyError(error);
                    }
                    //  Reload the school table as new school  got added successfully 
                    try {
                        const response = await QueryRequest(GET_ALL_SCHOOL_QUERY);
                        dispatch(setSchoolTableData(response?.data?.getAllSchools?.items))
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                    notifySuccess(`School "${createdSchool}" created successfully.`);
                    // Dispatch an action to clear form and errors
                    formElement.reset();
                    dispatch(clearForm({ formName: 'addSchool' }));
                    setErrors({});
                } else if (responseStatus === "Failure") {
                    notifyError(response.data.createOneSchool.error.message)
                }

            } catch (error) {
                console.error('Error creating school:', error);
                notifyError('An error occurred while saving. Please try again.');
            } finally {
                dispatch(setIsLoading(false));
            }
        }
    };

    const handleDistrictChange = (selectedOption) => {
        dispatch(updateForm({ formName: 'addSchool', formData: { district: selectedOption } }));
    };

    const handleStateChange = (selectedOption) => {
        dispatch(updateForm({ formName: 'addSchool', formData: { state: selectedOption } }));
    };

    return (
        <div>
            <ToastContainer />
            <h2 className="text-2xl font-bold mb-6">Add School</h2>
            <div className="max-w mx-auto bg-white p-8 border shadow-md rounded">
                <form id="addSchool">
                    <h2 className=' text-xl text-black font-bold mb-3'>Name of the School:
                        <Tooltip title={<span style={{ fontSize: 14 }}>School name should be max length of 50 character</span>} placement="bottom-end" arrow>
                            <InfoIcon style={{ marginLeft: 4 }} />
                        </Tooltip>
                        {errors.schoolName && <span className='text-red-500 pl-2'>*</span>}
                    </h2>
                    <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                        <input
                            type="text"
                            id="schoolName"
                            name="schoolName"
                            value={schoolData.schoolName}
                            maxLength={50}
                            onChange={(e) => handleChange('schoolName', e.target.value)}
                            className={`w-full border p-2 rounded ${errors.schoolName ? 'border-red-500' : ''}`}
                        />
                    </div>


                    <h2 className=' text-xl text-black font-bold mb-3'>Address:</h2>
                    <div className="mb-4 md:flex md:items-center">

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="address1" className="block text-gray-700 text-sm font-bold mb-4">
                                Address 1
                                {errors.address1 && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <input
                                type="text"
                                id="address1"
                                name="address1"
                                className={`w-full border p-2 rounded ${errors.address1 ? 'border-red-500' : ''}`}
                                value={schoolData.address1}
                                onChange={(e) => handleChange('address1', e.target.value)}
                            />
                        </div>

                        <div className="md:w-1/4 me-4">
                            <label htmlFor="address2" className="block text-gray-700 text-sm font-bold mb-4">
                                Address 2
                                {errors.address2 && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <input
                                type="text"
                                id="address2"
                                name="address2"
                                className={`w-full border p-2 rounded ${errors.address2 ? 'border-red-500' : ''}`}
                                value={schoolData.address2}
                                onChange={(e) => handleChange('address2', e.target.value)}
                            />
                        </div>

                        <div className={`md:w-1/4 mb-4 md:mb-0 me-4 relative ${errors.district ? 'has-error' : ''}`}>
                            <label htmlFor="district" className="block text-gray-700 text-sm font-bold mb-4">
                                District
                                {errors.district && <span className='text-red-500 pl-2'>*</span>}

                            </label>
                            <div className={`w-full border rounded ${errors.district ? 'border-red-500' : 'border-none'}`}>
                                <Select
                                    options={tamilNaduDistrictsData}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    value={schoolData.district}
                                    onChange={handleDistrictChange}
                                    className='z-50'
                                />
                            </div>
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="state" className="block text-gray-700 text-sm font-bold mb-4">
                                State
                                {errors.state && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <div className={`w-full border rounded ${errors.state ? 'border-red-500' : 'border-none'}`}>

                                <Select
                                    options={indianStatesData}
                                    isSearchable
                                    isClearable
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.key}
                                    getOptionValue={(option) => option.value}
                                    value={schoolData.state}
                                    onChange={handleStateChange}
                                    className='z-50'
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mb-4 md:flex md:items-center">
                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="pincode" className="block text-gray-700 text-sm font-bold mb-4">
                                Pincode
                                {errors.pincode && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <input
                                type="text"
                                id="Pincode"
                                name="Pincode"
                                maxLength={6}
                                className={`w-full border p-2 rounded ${errors.pincode ? 'border-red-500' : ''}`}
                                value={schoolData.pincode}
                                onChange={(e) => handleChange('pincode', e.target.value)}
                            />
                        </div>
                    </div>

                    <h2 className=' text-xl text-black font-bold mb-3'>School Contact Detail:</h2>
                    <div className="mb-4 md:flex md:items-center">
                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-4">
                                Phone Number
                                {errors.phoneNumber && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                maxLength={10}
                                className={`w-full border p-2 rounded ${errors.phoneNumber ? 'border-red-500' : ''}`}
                                value={schoolData.phoneNumber}
                                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                            />
                            {/* {errors.phoneNumber && <p className='text-red-500 mt-2'>{errors.phoneNumber}</p>} */}
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="emailID" className="block text-gray-700 text-sm font-bold mb-4">
                                Email ID
                                {errors.emailID && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <input
                                type="email"
                                id="emailID"
                                name="emailID"
                                className={`w-full border p-2 rounded ${errors.emailID ? 'border-red-500' : ''}`}
                                value={schoolData.emailID}
                                onChange={(e) => handleChange('emailID', e.target.value)}
                            />
                            {/* {errors.emailID && <p className='text-red-500 mt-2'>{errors.emailID}</p>} */}
                        </div>

                        <div className="md:w-1/4 mb-4 md:mb-0 me-4">
                            <label htmlFor="schoolIcon" className="block text-gray-700 text-sm font-bold mb-4">
                                School logo
                                {errors.schoolIcon && <span className='text-red-500 pl-2'>*</span>}
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                className={`w-full border p-2 rounded ${errors.schoolIcon ? 'border-red-500' : ''}`}
                            />
                            {/* {errors.schoolIcon && <p className='text-red-500 mt-2'>{errors.schoolIcon}</p>} */}
                        </div>
                    </div>

                    <div className='flex flex-row space-x-5  justify-end'>
                        <button
                            type="button"
                            onClick={handleClear}
                            className=" lg:px-16 lg:py-2 md:px-10 md:py-0.5  sm:px-10 sm:py-1 py-2 px-6 border border-blue-900 text-blue-900 bg-white  rounded transition-all ">
                            <FontAwesomeIcon icon={faTimes} className='' />Clear
                        </button>
                        <button
                            type="submit"
                            onClick={handleSave}
                            className="lg:px-16 lg:py-2 md:py-0.5 md:px-10 sm:px-10 sm:py-1 px-6 py-2 border border-blue-900 text-white bg-blue-900  rounded transition-all ">
                            {isLoading ? (
                                <div className="flex items-center justify-center w-full">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                                </div>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faSave} /> Save
                                </>
                            )}
                        </button>

                    </div>
                    <div className='text-red-500 mb-4'>
                        {/* {Object.values(errors).some((error) => error) && 'All fields are required.'} */}
                    </div>
                </form >
                {/* END OF FORM */}
                < hr className="border-t border-gray-300 my-8 mb-28" />
                < AddSchoolTable />
            </div >
        </div >
    );
};

export default AddSchool