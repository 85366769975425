import { gql } from "@apollo/client";


export const CREATE_SCHOOL_MUTATION = gql`
  mutation createOneSchool($data: schoolInput!) {
    createOneSchool(data: $data) {
      id
      status
      error {
        statusCode
        message
      }
    }
  }
`;

export const UPDATE_SCHOOL_MUTATION = gql`
mutation ($id: String!, $data: schoolInput) {
  updateOneSchool (
      id: $id,
      data: $data
  ) {
      __typename
      id
      status
      error { 
          statusCode
          message
      }
  }
}
`

export const DELETE_SCHOOL_MUTATION = gql`
mutation ($id: String!) {
  deleteOneSchool (
      id: $id
  ) {
      __typename
      id
      status
      error { 
          statusCode
          message
      }
  }
}
`
export const CREATE_TEACHER_MUTATION = gql`
mutation ($data: teacherInput) {
  createOneTeacher (
      data: $data
  ) {
      __typename
      id
      status
      error {
          statusCode
          message
      }
  }
}
`
export const UPDATE_TEACHER_MUTATION = gql`
mutation ($id: String!, $data: teacherInput) {
    updateOneTeacher (
        id: $id,
        data: $data
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}
`

export const UPDATE_STUDENT = gql`
mutation ($data: updateStudentInput) {
    updateStudent (
        data: $data
    ) {
        __typename
        clazzIdModified
        status
        newParentId
        error { 
            statusCode
            message
        }
    }
}
`

export const DELETE_TEACHER_MUTATION = gql`
mutation ($id: String!) {
    deleteOneTeacher (
        id: $id
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
  }
`
export const CREATE_CLASS_MUTATION = gql`
mutation ($data: clazzInput) {
  createOneClazz (
      data: $data
  ) {
      __typename
      id
      status
      error { 
          statusCode
          message
      }
  }
}
`

export const UPDATE_CLASS_MUTATION = gql`
mutation ($id: String!, $data: clazzInput) {
  updateOneClazz (
      id: $id,
      data: $data
  ) {
      __typename
      id
      status
      error { 
          statusCode
          message
      }
  }
}
`
export const DELETE_CLASS_MUTATION = gql`
mutation ($id: String!) {
  deleteOneClazz (
      id: $id
  ) {
      __typename
      id
      status
      error { 
          statusCode
          message
      }
  }
}
`

export const CREATE_PARENT_KID_MUTATION = gql`
mutation ($data: schoolParentKidInputList) {
    createSchoolParentKid (
        data: $data
    ) {
        __typename
        items { 
            sno
            parentId
            pincode
            parentName
            mobileNumber
            kids { 
                kidId
                clazzId
                studentName
                gender
                dob
            }
            existingParentId
            sameParentAsSno
            isParentStudentCreated
            validationMessage
        }
        isInputListHasValidationErrors
        error { 
            statusCode
            message
        }
    }
}
`
export const SEND_SMS_TO_PARENT = gql`
mutation ($data: sendSMSInput) {
    sendSMSToParent (
        data: $data
    ) {
        __typename
        items { 
            id
            mobileNumber
            deliveryStatus
            sentAt
            cancelled
        }
    }
}
`

export const CANCEL_SMS_MUTATION =gql`
mutation ($data: sendSMSInput) {
    cancelSMS (
        data: $data
    ) {
        __typename
        items { 
            id
            mobileNumber
            deliveryStatus
            sentAt
            cancelled
        }
    }
}`

export const UPDATE_TEACHERS_MARK =gql`
mutation ($kidId: Int!, $teachersMark: Int!) {
    updateTeachersMark (
        kidId: $kidId,
        teachersMark: $teachersMark
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}`

export const SENT_EMAIL_OTP_RESET_PASSWORD =gql`
query ($mobileNumber: String, $email: String, $type: String!) {
    sentUserOtp (
        mobileNumber: $mobileNumber,
        email: $email,
        type: $type
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}`

export const RESET_PASSWORD =gql`
mutation ($email: String!, $password: String!, $newPassword: String!, $emailOtp: String, $email_otp_required: String) {
    resetPassword (
        email: $email,
        password: $password,
        newPassword: $newPassword,
        emailOtp: $emailOtp,
        email_otp_required: $email_otp_required
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}
`

export const FORGOT_PASSWORD =gql`
mutation ($mobileNumber: String!, $newPassword: String!) {
    forgotPassword (
        mobileNumber: $mobileNumber,
        newPassword: $newPassword
    ) {
        __typename
        id
        status
        error { 
            statusCode
            message
        }
    }
}
`

