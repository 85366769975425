import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis, ChartsYAxis } from '@mui/x-charts';
import Typography from '@mui/material/Typography';
import ReactToPrint from 'react-to-print';

const ProgressBarChart = React.forwardRef((props, ref) => {
    const [type, setType] = React.useState('bar');
    const [timePeriod, setTimePeriod] = React.useState('week');
    const [data] = React.useState(Array.from({ length: 50 }, () => Math.floor(Math.random() * 50) + 1));
    const [currentPage, setCurrentPage] = React.useState(0);

    const itemsPerPage = 10;

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleTimePeriodChange = (event) => {
        setTimePeriod(event.target.value);
    };

    // Pagination functions
    const startIndex = currentPage * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, data.length);
    const paginatedData = data.slice(startIndex, endIndex);

    // Generate X-axis data based on the length of paginated data
    const paginatedXAxisData = Array.from({ length: paginatedData.length }, (_, i) => (startIndex + i + 1).toString());

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (event, page) => {
        setCurrentPage(page - 1);
    };

    const seriesColor = "#1E40AF";

    return (
        <Box sx={{ width: '100%' }} ref={ref}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    select
                    value={type}
                    onChange={handleTypeChange}
                    label="Series type"
                    sx={{ minWidth: 150, marginRight: 2 }}
                >
                    <MenuItem value="line">Line</MenuItem>
                    <MenuItem value="bar">Bar</MenuItem>
                </TextField>
                <TextField
                    select
                    value={timePeriod}
                    onChange={handleTimePeriodChange}
                    label="Time Period"
                    sx={{ minWidth: 150 }}
                >
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                </TextField>
            </Box>
            <ResponsiveChartContainer
                layout="horizontal"
                series={[
                    {
                        type,
                        data: paginatedData,
                        color: seriesColor,
                        highlightScope: {
                            highlighted: "item",
                            faded: "global",
                        },
                    },
                ]}
                xAxis={[
                    {
                        data: paginatedXAxisData,
                        scaleType: 'band',
                        id: 'x-axis-id',
                        dataKey: 'xValue',
                    }
                ]}
                yAxis={[
                    {
                        label: timePeriod === 'week' ? 'Week' : 'Month'
                    }
                ]}
                height={400}
            >
                <BarPlot />
                <LinePlot />
                <ChartsXAxis position="bottom" axisId="x-axis-id" />
                <ChartsYAxis labelStyle={{ fontSize: 16, fontWeight: 'bold' }} position="left" />
            </ResponsiveChartContainer>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', textAlign: "center", marginTop: 2 }}>
                Marks
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                <Pagination count={totalPages} page={currentPage + 1} onChange={handlePageChange} showFirstButton showLastButton />
            </Box>
        </Box>
    );
});



const PrintComponent = () => {
    const componentRef = React.useRef();

    return (
        <div>
            <ReactToPrint
                trigger={() => <button className="lg:px-16 lg:py-2 md:px-10 sm:px-10 sm:py-1 py-2 px-6 border mb-5 border-blue-900 text-white bg-blue-900  rounded transition-all hover:text-blue-900 hover:bg-white">Print this out!</button>}
                content={() => componentRef.current}
            />
            <ProgressBarChart ref={componentRef} />
        </div>
    );
};

export default PrintComponent;
