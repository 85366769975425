import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import AddStudentForm from './components/addStudent/AddStudentForm';
import ProgressReport from './components/progressReport/ProgressReportForm';
import AddSchool from "./components/addSchool/AddSchool";
import AddTeacher from "./components/addTeacher/AddTeacher";
import AddClass from './components/addClass/AddClass';
import SendSMSform from './components/sendSms/sendSMSform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader, faSignOutAlt, faSchool, faChalkboardTeacher, faPersonChalkboard, faUserGraduate, faFileAlt, faMessage } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { getItemFromLocalStorage, clearValuesOnLogout } from '../helpers/api/utils';
import { GET_SCHOOL_DROPDOWN_QUERY } from '../helpers/api/Queries';
import { QueryRequest } from '../helpers/api/service_helper';
import { setSchools } from '../redux/common_reducer';
import { notifyError } from '../common/notifications';
import { useDispatch } from 'react-redux';
import './styles.css'; // CSS for animations

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tabBar, setTabBar] = useState(true);
  const [isExpanded, setExpanded] = useState(true);
  const [teacherId, setTeacherId] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [superAdminId, setSuperAdminId] = useState('');

  useEffect(() => {
    const userId = getItemFromLocalStorage('user_id');
    const token = getItemFromLocalStorage('user_token');
    const teacherIdGet = getItemFromLocalStorage('teacher_id');
    const superAdminIdGet = getItemFromLocalStorage('superAdmin_id');
    const schoolIdGet = getItemFromLocalStorage('school_id');

    if (userId && token) {
      setTeacherId(teacherIdGet);
      setSchoolId(schoolIdGet);
      setSuperAdminId(superAdminIdGet);
    } else {
      window.location.reload();
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolVariables = {};
        const schoolIdGet = getItemFromLocalStorage('school_id');
        const teacherSchoolIdDrop = getItemFromLocalStorage('teacher_school_id');
        if (teacherSchoolIdDrop) {
          schoolVariables.schoolId = teacherSchoolIdDrop;
        } else if (schoolIdGet) {
          schoolVariables.schoolId = schoolIdGet.id;
        }
        const responseSchool = await QueryRequest(GET_SCHOOL_DROPDOWN_QUERY, schoolVariables);
        const fetchedSchools = responseSchool.data.getSchoolDropDownOptions.options;
        dispatch(setSchools(fetchedSchools.map(school => ({ value: school.value, label: school.label }))));
      } catch (error) {
        console.error(error);
        notifyError(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleLogout = () => {
    clearValuesOnLogout();
    navigate('/login');
  };

  const isActive = (path) => location.pathname === path ? 'bg-blue-300 text-white' : 'text-black bg-blue-100';

  const Sidebar = () => (
    <CSSTransition
      in={tabBar}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div className='fixed z-50 h-screen bg-grey' style={{ width: '300px' }}>
        <div className="shadow-2xl h-full flex flex-col justify-between">
          <div>
            {isExpanded && (
              <ul className="list-none">
                <div>
                  <h2 className="bg-blue-900 mt-20 text-white p-4 select-none text-center items-center">
                    <FontAwesomeIcon icon={faBookOpenReader} className='text-white pr-4 text-lg' />
                    Main Menu
                  </h2>
                  {superAdminId && (
                    <Link to="/home/add-school">
                      <li className={`cursor-pointer ps-4 border-b border-gray-300 py-5 p-2 flex items-center justify-start space-x-2 ${isActive('/home/add-school')}`}>
                        <FontAwesomeIcon icon={faSchool} className='text-black' />
                        <h2 className='text-black text-center'>School</h2>
                      </li>
                    </Link>
                  )}
                  {(superAdminId || schoolId) && (
                    <>
                      <Link to="/home/add-teacher">
                        <li className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 flex items-center justify-start space-x-2 ${isActive('/home/add-teacher')}`}>
                          <FontAwesomeIcon icon={faChalkboardTeacher} className='text-black' />
                          <h2 className='text-black text-center'>Teacher</h2>
                        </li>
                      </Link>
                      <Link to="/home/add-class">
                        <li className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 flex items-center justify-start space-x-2 ${isActive('/home/add-class')}`}>
                          <FontAwesomeIcon icon={faPersonChalkboard} className='text-black' />
                          <h2 className='text-black text-center'>Class</h2>
                        </li>
                      </Link>
                    </>
                  )}
                  {(superAdminId || schoolId || teacherId) && (
                    <>
                      <Link to="/home/add-student">
                        <li className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 flex items-center justify-start space-x-2 ${isActive('/home/add-student')}`}>
                          <FontAwesomeIcon icon={faUserGraduate} className='text-black' />
                          <h2 className='text-black text-center'>Student</h2>
                        </li>
                      </Link>
                      <Link to="/home/send-sms">
                        <li className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 flex items-center justify-start space-x-2 ${isActive('/home/send-sms')}`}>
                          <FontAwesomeIcon icon={faMessage} className='text-black' />
                          <h2 className='text-black text-center'>SMS</h2>
                        </li>
                      </Link>
                      <Link to="/home/progress-report">
                        <li className={`cursor-pointer ps-4 py-5 p-2 border-b border-gray-300 flex items-center justify-start space-x-2 ${isActive('/home/progress-report')}`}>
                          <FontAwesomeIcon icon={faFileAlt} className='text-black' />
                          <h2 className='text-black text-center'>Progress Report</h2>
                        </li>
                      </Link>
                    </>
                  )}
                </div>
              </ul>
            )}
          </div>
          <ul>
            <li onClick={handleLogout} className="cursor-pointer py-2 px-5 mb-4 bg-red-500 text-white flex items-center justify-center space-x-2">
              <FontAwesomeIcon icon={faSignOutAlt} />
              <h2 className='text-center'>Logout</h2>
            </li>
          </ul>
        </div>
      </div>
    </CSSTransition>
  );

  return (
    <div className="flex flex-col">
      {/* Top Navbar */}
      <Navbar tabBar={tabBar} setTabBar={setTabBar} isExpanded={isExpanded} setExpanded={setExpanded} />
      <div className="flex">
        {tabBar && <Sidebar />}
        {/* Content Section */}
        <div className={`flex-1 ${tabBar ? "ml-80" : ""} flex flex-col p-4 overflow-y-auto mt-20`}>
          <Routes>
            <Route path="/" element={<Navigate to="progress-report" />} />
            <Route path="add-school" element={<AddSchool />} />
            <Route path="add-teacher" element={<AddTeacher />} />
            <Route path="add-class" element={<AddClass />} />
            <Route path="add-student" element={<AddStudentForm />} />
            <Route path="send-sms" element={<SendSMSform />} />
            <Route path="progress-report" element={<ProgressReport />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
