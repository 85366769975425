import { useMemo, useState, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, TableContainer, Button, Tooltip, Typography, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress } from '@mui/material';
import { Star as StarIcon, Close } from '@mui/icons-material';
import { UPDATE_TEACHERS_MARK } from '../../../helpers/api/Mutation';
import { MutateRequest } from '../../../helpers/api/service_helper';
import { notifyError, notifySuccess } from '../../../common/notifications';

const CustomModal = ({ headerText, tooltipText, modalText }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={tooltipText} sx={{
                borderRadius: '10px',
                backgroundColor: '#fff',
                color: '#000',
                boxShadow: 'white',
            }}>
                <HelpOutlineOutlinedIcon onClick={handleOpen} />
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '10px',
                }}>
                    <DialogContent >
                        <h2 style={{ color: "black", fontWeight: "bold", marginBottom: 20 }}>Marks Split </h2>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            <li>Task - 10 Marks</li>
                            <li>Assessment - 10 Marks</li>
                            <li>Quiz - 20 Marks</li>
                            <li>Greenstar - 10 Marks</li>
                            <li>Total - 50 Marks</li>
                        </ul>
                    </DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{ backgroundColor: "#1E40AF" }} onClick={handleClose}>OKAY</Button>
                    </Box>
                </Box>
            </Modal>
        </Box >
    );
};

function CircularProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 75) {
            return '#35D55D';
        } else if (value >= 40) {
            return '#FFC107';
        } else {
            return '#FF5722';
        }
    };

    const color = getColor(props.value);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    color: color,
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}


const Example = ({ tableData }) => {

    const [data, setData] = useState(tableData);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setData(tableData);
        setIsLoading(false);
    }, [tableData]);

    const tableBoxStyle = { width: '100%', display: 'flex', alignItems: 'center', justifyContent: "center" }

    const columns = useMemo(() => [
        { accessorKey: 'name', header: 'Student Name' },
        {
            accessorKey: 'class',
            header: "Class",
            size: 20,
        },
        {
            accessorKey: 'taskScoreAvg',
            header: "Task",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                const data = cell.getValue();
                return (
                    <Box sx={tableBoxStyle}>
                        <CircularProgressWithLabel
                            variant="determinate"
                            value={data}
                            sx={{
                                width: '30px',
                                height: '30px',
                                color: '#35D55D',
                            }}
                        />
                    </Box>
                );
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 100,
                min: 10,
                step: 10,
            },
        },
        {
            accessorKey: 'assessmentScoreAvg',
            header: "Self Assessment",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                const data = cell.getValue();
                return (
                    <Box sx={tableBoxStyle}>
                        <CircularProgressWithLabel
                            variant="determinate"
                            value={data}
                            sx={{
                                width: '30px',
                                height: '30px',
                                color: '#35D55D',
                            }}
                        />
                    </Box>
                );
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 100,
                min: 10,
                step: 10,
            },
        },
        {
            accessorKey: 'quizScoreAvg',
            header: "Quiz",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                const data = cell.getValue();
                return (
                    <Box sx={tableBoxStyle}>
                        <CircularProgressWithLabel
                            variant="determinate"
                            value={data}
                            sx={{
                                width: '30px',
                                height: '30px',
                                color: '#35D55D',
                            }}
                        />
                    </Box>
                );
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 100,
                min: 10,
                step: 10,
            },
        },
        {
            accessorKey: 'greenStarScoreAvg',
            header: "Green Star",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                const data = cell.getValue();
                return (
                    <Box sx={tableBoxStyle}>
                        <CircularProgressWithLabel
                            variant="determinate"
                            value={data}
                            sx={{
                                width: '30px',
                                height: '30px',
                                color: '#35D55D',
                            }}
                        />
                    </Box>
                );
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 100,
                min: 10,
                step: 10,
            },
        },
        {
            accessorKey: 'totalScoreAvg',
            header: "Total Mark",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return <Typography sx={tableBoxStyle}>{cell.getValue()}</Typography>
            },
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10 }}>{column.columnDef.header}</p>
                    <CustomModal />
                </div>
            ),
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 50,
                min: 1,
                step: 10,
            },

        },
        {
            accessorKey: 'goldenStarAward',
            header: "Golden star",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <Box sx={tableBoxStyle}>
                        {value === 0 ? '--' : (
                            <>
                                <StarIcon style={{ color: "goldenrod", fontSize: 30 }} />
                                <Close style={{ color: "black", fontSize: 12 }} />
                                <Typography > {value}</Typography>
                            </>
                        )
                        }
                    </Box >
                );
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive',
            muiFilterSliderProps: {
                marks: true,
                max: 50,
                min: 1,
                step: 10,
            },

        },
        {
            accessorKey: 'teachersMark',
            header: "Teacher's Mark",
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ row }) => {
                const [mark, setMark] = useState(parseInt(row.original.teachersMark, 10) || 0);
                const [loading, setLoading] = useState(false);

                const handleUpdate = async () => {
                    setLoading(true); // Start loading
                    const request = {
                        kidId: row.original.kidId,
                        teachersMark: parseInt(mark, 10)
                    };
                    try {
                        const { data } = await MutateRequest(UPDATE_TEACHERS_MARK, request);
                        if (data.updateTeachersMark.status === "Success") {
                            notifySuccess("Mark updated successfully");
                        } else {
                            const errorMsg = "Error occurred in updating teachers mark";
                            notifyError(errorMsg);
                            console.error(errorMsg);
                        }
                    } catch (error) {
                        notifyError("An unexpected error occurred");
                        console.error("Unexpected error:", error);
                    } finally {
                        setLoading(false); // Stop loading
                    }
                };

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 5 }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={mark}
                            onChange={(e) => setMark(e.target.value)}
                            sx={{ width: 80 }}
                        />
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ textTransform: "none", backgroundColor: "#1E40AF" }}
                            onClick={handleUpdate}
                        >
                            {loading ? (
                                <CircularProgress size={24} sx={{ color: "white" }} />
                            ) : (
                                "Update"
                            )}
                        </Button>
                    </Box>
                );
            }
        },

    ], []);

    const table = useMaterialReactTable({
        columns,
        data,
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 1000 : undefined,
            },
        }),
        // muiToolbarAlertBannerProps: isLoadingUsersError
        //     ? {
        //         color: 'error',
        //         children: 'Error loading data',
        //     }
        //     : undefined,
        muiTableContainerProps: {
            component: TableContainer,

            sx: {
                minHeight: '500px',
            },
        },
        // renderTopToolbarCustomActions: ({ table }) => (
        //     <Button
        //         variant="contained"
        //         sx={{ backgroundColor: 'rgb(30 58 138)', }}
        //         onClick={handleExportData}
        //         startIcon={<FileDownloadIcon />}
        //     >
        //         Export All Data
        //     </Button>
        // ),
        state: {
            isLoading: isLoading,
            // showAlertBanner: isLoadingUsersError,
            // showProgressBars: isFetchingUsers,
        },
    });

    return <MaterialReactTable table={table} />;
};

const ProgressTable = ({ tableData }) => (
    <Example tableData={tableData} />
);




export default ProgressTable;
