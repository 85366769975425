import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { notifySuccess, notifyError } from './notifications';
import { MutateRequest, QueryRequest } from '../helpers/api/service_helper';
import { CREATE_PARENT_KID_MUTATION } from '../helpers/api/Mutation';
import { useSelector, useDispatch } from 'react-redux';
import { setResponseFromServer, setStudentTableData } from '../redux/common_reducer';
import { clearForm, updateForm } from '../redux/formReducer';
import { getItemFromLocalStorage } from '../helpers/api/utils';
import { GET_ALL_STUDENTS_QUERY } from '../helpers/api/Queries';

export default function ModalPopup({ open, setOpen, handleClose, isAgreeButtonDisabled }) {
    const dispatch = useDispatch();
    const responseFromServer = useSelector(state => state.common.responseFromServer);
    const formData = useSelector((state) => state.form.addStudent);


    // Clear the input box in add student page for mobile number
    const handleChangePh = () => {
        setOpen(false);
        dispatch(updateForm({
            formName: 'addStudent',
            formData: {
                parentDetails: {
                    ...formData.parentDetails,
                    parentPhoneNumber: ''
                }
            }
        }));
    };

    useEffect(() => {

    }, [responseFromServer]);


    // Send the response to backend after validated 
    const handleAgree = async () => {
        setOpen(false);
        try {
            let items = [];
            const requestData = responseFromServer.map(item => {
                const pincode = item.pincode;
                return {
                    sno: item.sno,
                    parentName: item.parentName,
                    mobileNumber: item.mobileNumber,
                    pincode: pincode,
                    kids: item.kids.map(kid => ({
                        clazzId: kid.clazzId,
                        studentName: kid.studentName,
                        gender: kid.gender,
                        dob: kid.dob
                    }))
                };
            });
            items.push(requestData);
            const response = await MutateRequest(CREATE_PARENT_KID_MUTATION, {
                data: { items: requestData }
            });
            // If there is error display the error message other wise post the data
            if (response.data.createSchoolParentKid.error) {
                console.error(response.data.createSchoolParentKid.error.message);
                notifyError(response.data.createSchoolParentKid.error.message, { autoClose: 8000 })
                return;
            } else if (response.data.createSchoolParentKid.error === null) {
                dispatch(clearForm({ formName: 'addStudent' }));
                notifySuccess("Student created successfully");
                dispatch(setResponseFromServer(null));
                // Reload the student table as new student got added successfully
                // After adding the student condition is retrieve students based on the loged in user role
                const teacherIdGet = getItemFromLocalStorage('teacher_id');
                const queryParam = {};
                if (teacherIdGet) {
                    queryParam.teacherId = teacherIdGet.id
                } else {
                    queryParam.schoolId = formData.selectedSchool.value
                }
                try {
                    const response = await QueryRequest(GET_ALL_STUDENTS_QUERY, queryParam);
                    dispatch(setStudentTableData(response?.data?.getStudents?.items))
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

        } catch (error) {
            console.error('Error sending data to backend:', error);
            notifyError('An error occurred while sending data.');
        }
    };


    const buttonStyle = {
        border: '2px solid #1E40AF',
        color: '#FFFFFF',
        backgroundColor: '#1E40AF',
        borderRadius: '5px',
        transition: 'all 0.3s',
        cursor: 'pointer',
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpen(false);
                    }
                }}
            >

                {!isAgreeButtonDisabled && (
                    <DialogTitle style={{ fontWeight: "bold" }}>
                        Add the student to an existing parent?
                    </DialogTitle>
                )}

                {isAgreeButtonDisabled && (
                    <DialogTitle style={{ fontWeight: "bold" }}>
                        Resolve the validation errors
                    </DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>
                        {responseFromServer?.map((item, index) => (
                            <span key={index} dangerouslySetInnerHTML={{ __html: item.validationMessage }} />
                        ))}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    {!isAgreeButtonDisabled && (
                        <Button
                            onClick={handleChangePh}
                            style={buttonStyle}
                        >
                            Change Phone Number
                        </Button>
                    )}

                    {!isAgreeButtonDisabled && (
                        <Button
                            onClick={handleAgree}
                            autoFocus
                            style={buttonStyle}
                        >
                            Submit
                        </Button>
                    )}
                </DialogActions>
                <DialogActions style={{ justifyContent: "center" }}>
                    {isAgreeButtonDisabled && (
                        <Button
                            onClick={handleClose}
                            style={buttonStyle}
                        >
                            Okay
                        </Button>
                    )}</DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
